import React from "react";
import {Box, Button, Modal} from "@mui/material";
// import API from "../../../api/api";
import DispenseDialog from "./DispenseDialog";


export default function CheckoutModal({
                                          modalStatus, setModalStatus, patientInfo,
                                          patient, dispensedDrugs, medication
                                      }) {
    // const [confirmationCode, setConfirmationCode] = useState("")
    // const [confirmationStatus, setConfirmationStatus] = useState(false)

    // function code_confirmation(patient, code, setConfirmationStatus) {
    //     let data = {
    //         "patient": patient,
    //         "code": code,
    //         "institution": "a3060270-9a4f-4098-a365-c23144472956"
    //     }
    //
    //     API.post("pharmacy/medication/verify_unique_code/", data)
    //         .then(resp => {
    //             if (resp.status === 200 && resp.data["message"] === "Code verified") {
    //                 setConfirmationStatus(true);
    //                 alert("Code confirmed")
    //             } else {
    //                 alert("The code is not Coding")
    //             }
    //         })
    //         .catch(error => {
    //             setConfirmationStatus(false);
    //             alert(error.response.data.message)
    //         })
    // }


    // let confirmationDialog = (
    //     <>
    //         <Typography variant={"h3"}>Confirmation pane</Typography>
    //         <Typography>
    //             Please enter Patient Code below
    //         </Typography>
    //
    //         <TextField
    //             id={"confirmationCode"}
    //             value={confirmationCode}
    //             onChange={(e) => {
    //                 setConfirmationCode(e.target.value)
    //             }}
    //             inputProps={{maxLength: 5}}
    //         />
    //         <br/>
    //         <Button
    //             style={{
    //                 backgroundColor: "#105eb6",
    //                 borderRadius: 10,
    //                 color: "white",
    //                 alignItems: "center",
    //                 marginTop: 10
    //             }}
    //             onClick={() => {
    //                 code_confirmation(patient, confirmationCode, setConfirmationStatus);
    //             }}
    //         >
    //             Submit</Button>
    //     </>
    // )

    // if (confirmationStatus === false) {
    //     return (
    //         <Modal open={modalStatus}>
    //             <Box
    //                 style={{
    //                     position: 'absolute',
    //                     backgroundColor: 'white',
    //                     transform: 'translate(-50%, -50%)',
    //                     top: '50%',
    //                     left: '50%',
    //                     border: '1px solid #000',
    //                     width: "1050px",
    //                     height: "500px",
    //                     boxShadow: 24,
    //                     p: 4,
    //                     borderRadius: 10,
    //                     padding: 5
    //                 }}
    //             >
    //                 <Button
    //                     style={{
    //                         backgroundColor: "#6cea31",
    //                         borderRadius: 15,
    //                         color: "white",
    //                         alignItems: "center"
    //                     }}
    //                     onClick={() => {
    //                         setModalStatus(false);
    //                         setConfirmationStatus(false)
    //                     }
    //                     }
    //                 >
    //                     close</Button>
    //                 {confirmationDialog}
    //             </Box>
    //         </Modal>)
    // } else {
    return (
        <Modal open={modalStatus}>
            <Box
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    transform: 'translate(-50%, -50%)',
                    top: '50%',
                    left: '50%',
                    border: '1px solid #000',
                    width: "1050px",
                    height: "500px",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 10,
                    padding: 5,
                    overflow: "scroll"
                }}
            >
                <Button
                    style={{
                        backgroundColor: "#6cea31",
                        borderRadius: 15,
                        color: "white",
                        alignItems: "center",
                        borderBottom: 3
                    }}
                    onClick={() => {
                        setModalStatus(false);
                        // setConfirmationStatus(false)
                    }}
                >
                    close</Button>
                <DispenseDialog drugs={dispensedDrugs}
                                medicationList={medication}
                                patientInfo={patientInfo}
                                patient={patient}
                />
            </Box>
        </Modal>)
}