import React from "react";
import {Alert, Box, Button, Grid, Snackbar, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useForm} from "react-hook-form";
import API from "../../api/api";
import {NavLink} from "react-router-dom";

export default function Reset() {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('success');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const onSubmit = data => {
        // Handle form submission
        API.post('/account/reset_password/', data)
            .then(response => {
                // console.log(response.data);
                if (response.status === 200) {
                    setAlertMessage("Password reset link has been sent to your email");
                    setAlertSeverity('success');
                    setOpenAlert(true);
                    // Redirect to password_reset/verify
                    window.location.href = '/password_reset/verify';

                }
            })
            .catch(error => {
                console.log(error);
                setAlertMessage("Account not found. Please check your email and try again");
                setAlertSeverity('error');
                setOpenAlert(true);
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    let emailInputScreen = (
        <Grid container
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              style={{
                  backgroundImage: `url(${
                      process.env.PUBLIC_URL + "images/pages/signin-bg.jpg"
                  })`,
                  height: "100vh",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
              }}
              sx={{minHeight: '100vh'}}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 1,
                    padding: 4,
                    margin: 2,
                    maxHeight: 500,
                    boxShadow: 3,
                }}
            >
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{paddingLeft: 1, marginRight: 5}}
                >
                    <img
                        src="/images/afyasasa-main-logo.png"
                        alt="AfyaSasa Logo"
                        width={236.05}
                    />
                </Grid>
                <Typography variant="h4" sx={{marginBottom: 2}}>
                    Please enter your email below
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        required
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {value: /^\S+@\S+$/i, message: 'Invalid email address'}
                        })}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ''}
                    />
                    {errors.email && (
                        <Alert severity="error" sx={{marginBottom: 2}}>
                            {errors.email.message}
                        </Alert>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{marginTop: 1}}
                    >
                        Reset Password
                    </Button>

                    <NavLink
                        to="/login"
                        style={{
                            textDecoration: "none",
                            color: "#77c0a3",
                            marginTop: isMobile ? 15 : 30,
                        }}
                    >
                        <Button
                            type="button"
                            variant="outlined"
                            color="info"
                            fullWidth
                            sx={{marginTop: 1}}
                        >
                            Back to Login
                        </Button>
                    </NavLink>
                </form>
                <Snackbar
                    open={openAlert}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{vertical: "top", horizontal: "right"}}>
                    <Alert
                        onClose={handleClose}
                        severity={alertSeverity}
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Grid>
    );

    return (
        emailInputScreen
    );
}
