import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import API from "../../api/api";
import LabSession from "./laboratory";

export default function ConfirmTestsPatient() {

    const [patientTest, setPatientTests] = useState([]);
    const [selectRow, setRow] = useState(null);
    const [confirmation, setConfirmation] = useState(false);
    const [patient, setPatient] = useState({
        first_name: '',
        last_name: '',
    });

    useEffect(() => {
            API.get("laboratory/medicaltests/get_patients_with_tests/", {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    "staff": localStorage.getItem("user_id"),
                    "institution": localStorage.getItem("institution_id")
                }
            })
                .then((resp) => {
                        setPatientTests(resp.data)
                        // console.log(resp.data)
                    }
                ).catch((e) => {
                console.log(e)
            })
        }, []
    )

    function selectNextPatient(patient_id) {
        // This function will help choose the current patient in-case there is a mismatch
        let currentPatients = patientTest;

        for (let i = 0; i < currentPatients['length']; i++) {
            if (currentPatients[i].id === patient_id) {
                setPatient(currentPatients[i]);
                break;
            }
        }
    }

    let confirmPatient = (
        <Grid container
              direction={"row"}
              spacing={2}
              style={{
                  maxWidth: "96%",
                  paddingLeft: "10px",
                  marginLeft: "20px",
              }}>

            <Grid
                item
                container
                justifyContent={"space-between"}
            >

                <Grid item
                      style={{
                          justifyContent: "center",
                          alignItems: "center",
                      }}
                >
                    <Card style={{
                        borderRadius: 10,
                        padding: 5,
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <CardMedia>
                            <img src={"/images/user/1.jpg"} alt={"user Img"}/>
                        </CardMedia>
                        <CardContent>
                            <Typography variant={"h6"}>
                                Name: {patient ? patient.first_name : ""}
                                <span> {patient ? patient.last_name : ""}</span>
                            </Typography>

                            <Button
                                style={{
                                    borderRadius: 20,
                                    backgroundColor: "#77c0a3",
                                    color: "white",
                                    marginRight: 5
                                }}
                                endIcon={<CheckCircleIcon fontSize={"large"}/>}
                                onClick={() => {
                                    if (patient) {
                                        setConfirmation(true);
                                    } else {
                                        alert("No patient selected")
                                    }
                                }}
                            >
                                Confirm Patient
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <h4>Lab Test List</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 600}} size="small" aria-label="Today's appointments">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Patient</TableCell>
                                    <TableCell>Time</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {patientTest.length > 0 ? patientTest.map(({
                                                                               created_at,
                                                                               id,
                                                                               first_name,
                                                                               last_name,
                                                                           }) => (
                                    <TableRow
                                        key={id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        id={id}
                                        hover
                                        onClick={() => {
                                            selectNextPatient(id)
                                            setRow(id)
                                        }}
                                        selected={selectRow === id}
                                    >

                                        <TableCell scope={"row"}>
                                            {first_name} {last_name}
                                        </TableCell>
                                        <TableCell>{new Date(created_at).toLocaleTimeString([], {
                                            hour: '2-digit', minute: '2-digit', hour12: true
                                        })}</TableCell>
                                    </TableRow>
                                )) : <TableCell>
                                    <Typography variant={"h6"}>
                                        No data to show
                                    </Typography>
                                </TableCell>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        </Grid>
    )

    if (confirmation === false) {
    return (
        <div>
            {confirmPatient}
        </div>
    )
} else {
    return (
        <LabSession patient_id={patient.id} patientDetails={{"fname": patient.first_name, "lname": patient.last_name}} />
    )
    }
}