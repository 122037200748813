import React from 'react';
import HistoryLabsItemAccordion from "../HistoryLabsItemAccordion";

const ImagingTab = ({ labs }) => {
    return (
        <div>
            {labs.map((lab) => (
                <HistoryLabsItemAccordion
                    key={lab.id}
                    title={`Imaging Test: ${lab.test_name}`}
                    details={lab}
                />
            ))}
        </div>
    );
};

export default ImagingTab;
