import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, MenuItem, Modal, TextField, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function InstitutionSelect({ open, institution, setOpen }) {
    const [selectedInstitution, setSelectedInstitution] = useState('');

    useEffect(() => {
        if (institution.length > 0) {
            const { institution_id, institution_name } = institution[0];
            setSelectedInstitution(institution_name);
            setInstitutionToLocalStorage(institution_id, institution_name);
        }
    }, [institution]);

    const setInstitutionToLocalStorage = (id, name) => {
        localStorage.setItem("institution_id", id);
        localStorage.setItem("institution_name", name);
    };

    const handleChange = (event) => {
        const selectedName = event.target.value;
        const selectedOption = institution.find(option => option.institution_name === selectedName);
        setSelectedInstitution(selectedName);
        setInstitutionToLocalStorage(selectedOption.institution_id, selectedName);
    };

    return (
        <Modal open={open}>
            <Box>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        height: "100vh",
                        backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            backgroundColor: "white",
                            padding: 20,
                            borderRadius: 10,
                            width: "50%",
                        }}
                    >
                        <CheckCircleIcon
                            style={{
                                color: "green",
                                fontSize: 100,
                            }}
                        />
                        <Typography variant="h6">Select Institution</Typography>
                        <TextField
                            select
                            type="text"
                            style={{ width: "100%" }}
                            value={selectedInstitution}
                            onChange={handleChange}
                        >
                            {institution.map(({ institution_id, institution_name }) => (
                                <MenuItem
                                    key={institution_id}
                                    value={institution_name}
                                >
                                    {institution_name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                margin: 10,
                            }}
                            onClick={() => {
                                setOpen(false);
                                window.location = "/";
                            }}
                        >
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
