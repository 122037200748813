import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {styled} from '@mui/material/styles';

const StyledAccordion = styled(Accordion)(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    marginBottom: '10px',
}));

const HistoryMedicationAccordion = ({title, details}) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <StyledAccordion expanded={expanded} onChange={handleToggle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant="h6" color="primary">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="body2">
                    <Typography variant={'body1'}>
                        <strong>Instructions:</strong> {details.instructions || 'N/A'}
                    </Typography>
                    <Typography variant={'body1'}>
                        <strong>Prescription Date:</strong> {new Date(details.last_updated).toLocaleString() || 'N/A'}
                    </Typography>
                </Typography>
            </AccordionDetails>
        </StyledAccordion>
    );
};


export default HistoryMedicationAccordion;
