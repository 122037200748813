import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import API from "../../api/api";
import DoctorSession from "./DoctorSession"

export default function Consultation() {
    const [appointment, setAppointment] = useState([]);
    const [recentConsultations, setRecentConsultations] = useState([]);
    const [reviewMode, setReviewMode] = useState(false);
    const [labReviewMode, setLabReviewMode] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [patient, setPatient] = useState({
        patient_first_name: '',
        patient_last_name: '',
        patient_phone: '',
    });
    const [reviewPatient, setReviewPatient] = useState(null);
    const [review, setReview] = useState([]);
    const [selectRow, setRow] = useState(null);
    const [selectedAppointment, setSelectedAppointment] = useState(null);

    useEffect(() => {
        API.get("appointments/", {
            params: {
                institution_id: localStorage.getItem("institution_id"),
                staff_id: localStorage.getItem("user_id"),
            }
        })
            .then(resp => {
                if (resp.data["results"].length > 0) {
                    setAppointment(resp.data["results"]);
                    let currentPatient = resp.data["results"]
                    setPatient(currentPatient[0])
                    setSelectedAppointment(currentPatient[0].id)
                }
            })
    }, []);

    useEffect(() => {
        API.get("consultation/get_recently_viewed_consultations/", {
            params: {
                institution_id: localStorage.getItem("institution_id"),
                staff_id: localStorage.getItem("user_id"),
            }
        })
            .then(resp => {
                    if (resp.data.length > 0) {
                        setRecentConsultations(resp.data);
                    }
                }
            )
    }, []);

    // Check for appointments that are pending_review
    useEffect(() => {
        API.get("/laboratory/medicaltests/get_pending_review_tests/", {
            params: {
                institution_id: localStorage.getItem("institution_id"),
            }
        })
            .then(resp => {
                setReview(resp.data);
            })
    }, []);

    function selectNextPatient(patient_id) {
        // This function will help choose the current patient in-case there is a mismatch
        let currentPatients = appointment

        for (let i = 0; i < currentPatients['length']; i++) {
            if (currentPatients[i].patient === patient_id) {
                setPatient(currentPatients[i]);
                break;
            }
        }
    }

    function selectReviewPatient(rev_patient) {
        let to_be_reviewed = review
        let review_patient = {}

        for (let i = 0; i < review.length; i++) {
            if (to_be_reviewed[i].id === rev_patient) {
                // build patient object for display
                review_patient = {
                    patient_first_name: to_be_reviewed[i].first_name,
                    patient_last_name: to_be_reviewed[i].last_name,
                    patient: to_be_reviewed[i].id,
                    id: to_be_reviewed[i].id,
                }
                setReviewPatient(to_be_reviewed[i])
                setPatient(review_patient)
                break
            }
        }
    }

    function selectRecentlyViewedPatient(recent_patient) {
        let to_be_reviewed = recentConsultations
        let review_patient = {}

        for (let i = 0; i < to_be_reviewed.length; i++) {
            if (to_be_reviewed[i].id === recent_patient) {
                // build patient object for display
                review_patient = {
                    patient_first_name: to_be_reviewed[i].patient_first_name,
                    patient_last_name: to_be_reviewed[i].patient_last_name,
                    patient: to_be_reviewed[i].patient,
                    id: to_be_reviewed[i].id,
                }
                setReviewPatient(to_be_reviewed[i])
                setPatient(review_patient)
                break
            }
        }
    }

    let confirmPatient = (
        <Grid container
              direction={"row"}
              spacing={2}
              style={{
                  maxWidth: "96%",
                  paddingLeft: "10px",
                  marginLeft: "20px",
              }}>

            <Grid
                item
                container
                direction={"row"}
                justifyContent={"space-evenly"}
            >

                <Grid item
                      style={{
                          justifyContent: "center",
                          alignItems: "center",
                      }}
                >
                    <Card style={{
                        borderRadius: 10,
                        padding: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: 600
                    }}>
                        <CardMedia>
                            <img src={"/images/user/1.jpg"} alt={"user Img"}/>
                        </CardMedia>
                        <CardContent>
                            <Typography variant={"h6"}>
                                Name: {patient ? patient.patient_first_name : ""}
                                <span> {patient ? patient.patient_last_name : ""}</span>
                                <br/>
                                Phone: {patient ? patient.patient_phone : ""}
                            </Typography>

                            <Button
                                style={{
                                    borderRadius: 20,
                                    backgroundColor: "#77c0a3",
                                    color: "white",
                                    marginRight: 5
                                }}
                                endIcon={<CheckCircleIcon fontSize={"large"}/>}
                                onClick={() => {
                                    if (patient) {
                                        setConfirmation(true);
                                    } else {
                                        alert("No patient selected")
                                    }
                                }}
                            >
                                Confirm Patient
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <h4>Appointment List</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 600}} size="small" aria-label="Today's appointments">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Patient</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Issue</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appointment.length > 0 ? appointment.map(({
                                                                               appointment_time,
                                                                               id,
                                                                               patient,
                                                                               patient_first_name,
                                                                               patient_last_name,
                                                                               reason
                                                                           }) => (
                                    <TableRow
                                        key={id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        id={id}
                                        hover
                                        onClick={() => {
                                            selectNextPatient(patient)
                                            setRow(id)
                                            setSelectedAppointment(id)
                                        }}
                                        selected={selectRow === id}
                                    >

                                        <TableCell scope={"row"}>
                                            {patient_first_name} {patient_last_name}
                                        </TableCell>
                                        <TableCell>{new Date(appointment_time).toLocaleTimeString([], {
                                            hour: '2-digit', minute: '2-digit', hour12: true
                                        })}</TableCell>
                                        <TableCell>{reason}</TableCell>
                                    </TableRow>
                                )) : <TableCell>
                                    <Typography variant={"h6"}>
                                        No data to show
                                    </Typography>
                                </TableCell>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Grid
                item
                container
                direction={"row"}
                justifyContent={"space-evenly"}
            >
                <Grid item>
                    <h4>Lab Review List</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 600}} size="small" aria-label="Today's appointments">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Patient</TableCell>
                                    <TableCell>Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {review.length > 0 ? review.map(({
                                                                     last_updated,
                                                                     id,
                                                                     first_name,
                                                                     last_name,
                                                                     user
                                                                 }) => (
                                    <TableRow
                                        key={id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        id={id}
                                        hover
                                        onClick={() => {
                                            selectReviewPatient(id);
                                            setLabReviewMode(true);
                                            setRow(id);
                                        }}
                                        selected={selectRow === id}
                                    >

                                        <TableCell scope={"row"}>
                                            {first_name} {last_name}
                                        </TableCell>
                                        <TableCell>{new Date(last_updated).toLocaleTimeString([], {
                                            hour: '2-digit', minute: '2-digit', hour12: true
                                        })}</TableCell>
                                        <TableCell>
                                            <Button
                                                style={{
                                                    borderRadius: 20,
                                                    backgroundColor: "#77c0a3",
                                                    color: "white",
                                                    marginRight: 5
                                                }}
                                                endIcon={<CheckCircleIcon fontSize={"large"}/>}
                                                onClick={() => {
                                                    selectReviewPatient(user)
                                                    setLabReviewMode(true);
                                                    setRow(id);
                                                    setConfirmation(true);
                                                }}
                                            >
                                                Review
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableCell>
                                    <Typography variant={"h6"}>
                                        No data to show
                                    </Typography>
                                </TableCell>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <h4>Recently Seen</h4>
                    <TableContainer component={Paper} size="small" aria-label="Recently Seen Patients">
                        <Table sx={{minWidth: 600}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Patient</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recentConsultations.length > 0 ? recentConsultations.map(({
                                                                                             last_updated,
                                                                                             id,
                                                                                             patient_first_name,
                                                                                             patient_last_name,
                                                                                             user
                                                                                         }) => (
                                    <TableRow
                                        key={id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        id={id}
                                        hover
                                        onClick={() => {
                                            const specificConsultation = recentConsultations.find(consultation => consultation.id === id);
                                            selectRecentlyViewedPatient(id)
                                            setRecentConsultations(specificConsultation)
                                            selectNextPatient(user)
                                            setRow(id)
                                            setReviewMode(true);
                                        }}
                                        selected={selectRow === id}
                                    >

                                        <TableCell scope={"row"}>
                                            {patient_first_name} {patient_last_name}
                                        </TableCell>
                                        <TableCell>{new Date(last_updated).toLocaleTimeString([], {
                                            hour: '2-digit', minute: '2-digit', hour12: true
                                        })}</TableCell>
                                        <TableCell>
                                            <Button
                                                style={{
                                                    borderRadius: 20,
                                                    backgroundColor: "#77c0a3",
                                                    color: "white",
                                                    marginRight: 5
                                                }}
                                                endIcon={<CheckCircleIcon fontSize={"large"}/>}
                                                onClick={() => {
                                                    const specificConsultation = recentConsultations.find(consultation => consultation.id === id);
                                                    // console.log('Specific consultation: ',specificConsultation)
                                                    if (specificConsultation) {
                                                        selectRecentlyViewedPatient(user);
                                                        setConfirmation(true);
                                                        setReviewMode(true);
                                                        setRecentConsultations(specificConsultation)
                                                    } else {
                                                        alert("Appointment not found");
                                                    }
                                                }}
                                            >
                                                Review
                                            </Button>

                                        </TableCell>
                                    </TableRow>
                                )) : <TableCell>
                                    <Typography variant={"h6"}>
                                        No data to show
                                    </Typography>
                                </TableCell>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        </Grid>
    )


    if (confirmation === false) {
        return (
            {...confirmPatient}
        )
    }
    return (
        <DoctorSession
            patient={patient}
            reviewMode={reviewMode}
            labReviewMode={labReviewMode}
            recentConsultation={recentConsultations}
            appointment={appointment}
            selectedAppointment={selectedAppointment}
            review_patient={reviewPatient}
            setConfirmation={setConfirmation}
        />
    )
}
