import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import PropTypes from 'prop-types';

const DataTable = ({test_type, patientDetails, setCurrentTest, updatedTests}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableCell>Test</TableCell>
                    <TableCell>Patient</TableCell>
                    <TableCell>Status</TableCell>
                </TableHead>
                <TableBody>
                    {test_type?.map(({id, test_name, status}, index) => {
                        const isUpdated = updatedTests.includes(id); // Check if the test is updated
                        return (
                            <TableRow
                                key={id}
                                id={id}
                                onClick={() => {
                                    setCurrentTest(test_type[index]);
                                }}
                                style={{
                                    backgroundColor: isUpdated ? "#dff0d8" : "inherit", // Highlight updated rows
                                    cursor: "pointer"
                                }}
                            >
                                <TableCell>{test_name}</TableCell>
                                <TableCell>{patientDetails.fname} {patientDetails.lname}</TableCell>
                                <TableCell>{isUpdated ? "Updated" : status}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

DataTable.defaultProps = {
    test_type: [],
    patientDetails: {},
    setCurrentTest: () => {},
    updatedTests: [], // Default to an empty array
}

DataTable.propTypes = {
    test_type: PropTypes.array.isRequired,
    patientDetails: PropTypes.object.isRequired,
    setCurrentTest: PropTypes.func.isRequired,
    updatedTests: PropTypes.array.isRequired, // Ensure updatedTests is passed
}

export default DataTable;
