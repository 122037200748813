import API from "../../../api/api";


function Logout() {
    API.post("logout/user/", {
        "token": localStorage.getItem("auth_token"),
        "email": localStorage.getItem("user_email"),
    })
        .then((response) => {
            localStorage.removeItem("token");
            localStorage.clear();
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
        });
}

export default Logout;