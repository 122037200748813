import React from "react";
import {NavLink} from "react-router-dom";
import {ListItemIcon, ListItemText, MenuItem, MenuList} from "@mui/material";
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import GroupIcon from '@mui/icons-material/Group';
import MedicationIcon from '@mui/icons-material/Medication';
import ScienceIcon from '@mui/icons-material/Science';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';

// TODO: Implement a function/prop that keeps track of current tab that one is in and have it highlighted. NavLink

export default function sideMenu() {
    return (
        <div style={{
            backgroundColor: "#78C0A3",
            borderRadius: 15,
            marginLeft: 15
        }}
        >
            <MenuList style={{color: "white"}}>
                <NavLink
                    to={"/"}
                    style={{
                        textDecoration: "none",
                        color: "white"
                    }}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <DashboardCustomizeRoundedIcon style={{color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText>Dashboard</ListItemText>
                    </MenuItem>
                </NavLink>
                <NavLink
                    to={"checkin"}
                    style={{
                        textDecoration: "none",
                        color: "white"
                    }}
                >
                    {localStorage.getItem("staff_type") === "admin" ||
                    localStorage.getItem("user_type") === "admin" ||
                    localStorage.getItem("staff_type") === "nurse"
                        ? (
                            <MenuItem>
                                <ListItemIcon>
                                    <DomainVerificationIcon style={{color: "white"}}/>
                                </ListItemIcon>
                                <ListItemText>
                                    Check-in
                                </ListItemText>
                            </MenuItem>
                        ) : null}

                </NavLink>
                <NavLink
                    to={"consultation"}
                    style={{
                        textDecoration: "none",
                        color: "white"
                    }}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <AccountBoxIcon style={{color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText>
                            Doctor's Appointments
                        </ListItemText>
                    </MenuItem>
                </NavLink>
                <NavLink
                    to={"/lab"}
                    style={{
                        textDecoration: "none",
                        color: "white"
                    }}
                >
                    {localStorage.getItem("staff_type") === "admin" ||
                    localStorage.getItem("user_type") === "admin" ||
                    localStorage.getItem("staff_type") === "lab_technician"
                        ? (
                            <MenuItem>
                                <ListItemIcon>
                                    <ScienceIcon style={{color: "white"}}/>
                                </ListItemIcon>
                                <ListItemText>
                                    Labs & Tests
                                </ListItemText>
                            </MenuItem>
                        ) : null}

                </NavLink>
                <NavLink
                    to={"/medication"}
                    style={{
                        textDecoration: "none",
                        color: "white"
                    }}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <MedicationIcon style={{color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText>
                            Medication
                        </ListItemText>
                    </MenuItem>
                </NavLink>
                {/*<NavLink*/}
                {/*    to={"/calendar"}*/}
                {/*    style={{*/}
                {/*        textDecoration: "none",*/}
                {/*        color: "white"*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <MenuItem>*/}
                {/*        <ListItemIcon>*/}
                {/*            <EventAvailableIcon style={{color: "white"}}/>*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText>*/}
                {/*            Calendar & Reminders*/}
                {/*        </ListItemText>*/}
                {/*    </MenuItem>*/}
                {/*</NavLink>*/}
                <NavLink
                    to={"users"}
                    style={{
                        textDecoration: "none",
                        color: "white"
                    }}
                >
                    {localStorage.getItem("staff_type") === "Admin" ||
                    localStorage.getItem("user_type") === "admin" ? (
                        <MenuItem>
                            <ListItemIcon>
                                <GroupIcon style={{color: "white"}}/>
                            </ListItemIcon>
                            <ListItemText>
                                Users
                            </ListItemText>
                        </MenuItem>
                    ) : null}

                </NavLink>

            </MenuList>
        </div>
    )
}

