import React from 'react';
import HistoryMedicationAccordion from "../HistoryMedicationAccordion";

const MedicationsTab = ({ medication }) => {
    return (
        <div>
            {medication.map((medication) => (
                <HistoryMedicationAccordion
                    key={medication.id}
                    title={`Medication: ${medication.medicine_name}`}
                    details={medication}
                />
            ))}
        </div>
    );
};

export default MedicationsTab;
