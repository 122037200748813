import {Button} from "@mui/material";
import React from "react";
import API from "../../../api/api";


function disableStaff(id: string) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to disable this staff member's access to your institution?")) {
        API.put("/staff/suspend_staff_access/", {
            user: id,
            institution_id: localStorage.getItem("institution_id")
        })
            .then((response) => {
                // console.log(response.data);
                alert("Staff access suspended");
                window.location.reload();
            });
    }
}


export default function DisableButton({user}) {
    return (
        <Button
            variant={"outlined"}
            color={"error"}
            size={"small"}
            onClick={() => {
                console.log(user);
                disableStaff(user.user)
            }}
        >Disable</Button>
    )
}
