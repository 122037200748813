import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    marginBottom: '10px',
}));

const HistoryItemAccordion = ({ title, details }) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <StyledAccordion expanded={expanded} onChange={handleToggle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" color="primary">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="body2">
                    <Typography variant={'body1'}>
                        <strong>Results:</strong> {details.results || 'N/A'}
                    </Typography>
                    <Typography variant={'body1'}>
                        <strong>Date:</strong> { new Date(details.last_updated).toLocaleString() || 'N/A'}
                        </Typography>
                    <Typography variant={'body1'}>
                        <strong>Other details:</strong> {details.other_details || 'N/A'}
                    </Typography>
                    <Typography variant="subtitle2">Status: <span style={{ color: getStatusColor(details.status) }}>{details.status.replace(/_/g, ' ')}</span></Typography>
                </Typography>
            </AccordionDetails>
        </StyledAccordion>
    );
};

const getStatusColor = (status) => {
    switch (status) {
        case 'completed':
            return 'green';
        case 'pending_review':
            return 'orange';
        case 'in_progress':
            return 'blue';
        default:
            return 'black';
    }
};
export default HistoryItemAccordion;
