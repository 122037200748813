import React, { useState } from "react";
import { Alert, Autocomplete, Box, Button, Grid, Snackbar, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import API from "../../api/api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment-timezone";

export default function NewOrg() {
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [idDocument, setIdDocument] = useState(null);
    const [businessRegistration, setBusinessRegistration] = useState(null);
    const timezones = moment.tz.names();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            name: "",
            user_first_name: "",
            user_last_name: "",
            org_email: "",
            org_phone: "",
            address: "",
            timezone: "",
            admin_type: "admin",
        },
    });

    async function handleFormSubmit(data) {
        setIsSubmitting(true); // Disable button during submission
        clearErrors(); // Clear any previous errors
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
        // Append files separately
        if (idDocument) formData.append("id_document", idDocument);
        if (businessRegistration) formData.append("business_registration", businessRegistration);


        try {
            const resp_data = await API.post("institutions/self_create_institution/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (resp_data.status === 201 || resp_data.status === 200) {
                setAlertMessage("Organization created successfully");
                setAlertSeverity('success');
                setOpenAlert(true);

                setTimeout(() => {
                    window.location.href = '/login';
                }, 3000);
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data) {
                const apiErrors = error.response.data;
                for (const [key, messages] of Object.entries(apiErrors)) {
                    if (key in data) {
                        setError(key, {
                            type: "manual",
                            message: messages.join(", "),
                        });
                    } else {
                        setAlertMessage(messages.join(", "));
                        setAlertSeverity('error');
                        setOpenAlert(true);
                    }
                }
            } else {
                setAlertMessage("An error occurred. Please try again");
                setAlertSeverity('error');
                setOpenAlert(true);
            }
        } finally {
            setIsSubmitting(false); // Re-enable submit button after error/success
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    };

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center"
              style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + "images/pages/signin-bg.jpg"})`,
                  height: "100vh",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
              }}
              sx={{ minHeight: '100vh' }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(handleFormSubmit)}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 1,
                    padding: 4,
                    margin: 2,
                    boxShadow: 3,
                    width: '100%',
                    maxWidth: 600,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h1>Register New Organization</h1>
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="user_first_name"
                            control={control}
                            rules={{ required: "Admin's first name is required" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Admin First Name"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.user_first_name}
                                    helperText={errors.user_first_name ? errors.user_first_name.message : null}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="user_last_name"
                            control={control}
                            rules={{ required: "Admin's last name is required" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Admin Last Name"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.user_last_name}
                                    helperText={errors.user_last_name ? errors.user_last_name.message : null}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: "Organization name is required" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Organization Name"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.name}
                                    helperText={errors.name ? errors.name.message : null}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="org_email"
                            control={control}
                            rules={{ required: "Organization email is required" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Organization Email"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.org_email}
                                    helperText={errors.org_email ? errors.org_email.message : null}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="org_phone"
                            control={control}
                            rules={{ required: "Organization phone number is required" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Organization Phone"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.org_phone}
                                    helperText={errors.org_phone ? errors.org_phone.message : null}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="address"
                            control={control}
                            rules={{ required: "Organization address is required" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Organization Address"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.address}
                                    helperText={errors.address ? errors.address.message : null}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="timezone"
                            control={control}
                            rules={{ required: "Timezone is required" }}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={timezones}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Timezone"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.timezone}
                                            helperText={errors.timezone ? errors.timezone.message : null}
                                        />
                                    )}
                                    onChange={(_, data) => field.onChange(data)} // Handle selection
                                />
                            )}
                        />
                    </Grid>

                    <Grid item container>
                        {/* Document upload fields for ID and business registration */}
                        <Grid item xs={12} sm={6}>
                            <label htmlFor="id-document">
                                Business owner ID Document (PDF)
                                <input
                                    id="id-document"
                                    type="file"
                                    accept="application/pdf"
                                    onChange={(e) => setIdDocument(e.target.files[0])} // No need for field.onChange
                                    required
                                />
                            </label>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <label htmlFor="business-registration">
                                Business Registration (PDF)
                                <input
                                    id="business-registration"
                                    type="file"
                                    accept="application/pdf"
                                    onChange={(e) => setBusinessRegistration(e.target.files[0])} // No need for field.onChange
                                    required
                                />
                            </label>
                        </Grid>
                    </Grid>


                    <Grid item xs={12}
                          spacing={2}
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            endIcon={<CheckCircleIcon />}
                            disabled={isSubmitting} // Disable during submission
                        >
                            {isSubmitting ? "Submitting..." : "Register Organization"}
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar
                    open={openAlert}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={alertSeverity}
                        variant="filled"
                        sx={{
                            width: '100%',
                            fontSize: '1.2rem',
                            padding: '20px',
                        }}
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Grid>
    );
}
