import React from 'react'
import {Grid, TextField, Typography} from "@mui/material";

/*
    This component is used to display the input fields for the user to enter their information
    and the text fields are used to display the information that the user has entered
    @param {string} field_name - Field name to be displayed to the user
 */
function InputField({field_name, value, error, helperText, onChange, field_label, type}) {
    return (
        <Grid item container>
            <Grid item xs={6}>
                <Typography variant={"h6"}>
                    {field_name}:
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id="outlined-basic"
                    label={field_label}
                    variant="outlined"
                    size={"small"}
                    value={value}
                    type={type}
                    error={error}
                    helperText={helperText}
                    onChange={e => onChange(e.target.value)}
                />
            </Grid>
        </Grid>
    );
}

export default InputField;