import React, { useState } from 'react';
import { Box, Button, Grid, InputAdornment, MenuItem, Modal, TextField, Typography, Snackbar, Alert } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Api from "../../api/api";

// Status options
const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'disabled', label: 'Disabled' },
];

const staffTypes = [
    { value: 'office_admin', label: 'Office Admin' },
    { value: 'nurse', label: 'Nurse' },
    { value: 'doctor', label: 'Doctor' },
    { value: 'lab_technician', label: 'Lab Technician' },
    { value: 'institute_admin', label: 'Institute Admin' },
];

export default function CreateUsers({ open, setOpen }) {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 10,
        width: "90%",
        maxWidth: "600px",
        maxHeight: "90vh",
        overflowY: "auto",
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            staffType: '',
            status: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            phone: Yup.string().required('Phone number is required'),
            staffType: Yup.string().required('Staff type is required'),
            status: Yup.string().required('Status is required'),
            password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
        }),
        onSubmit: async (values) => {
            try {
                const response = await Api.post('/staff/create/', {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    institution_id: localStorage.getItem("institution_id"),
                    email: values.email,
                    phone: "+254" + values.phone,
                    password: values.password,
                    staff_type: values.staffType,
                    status: values.status,
                });

                if (response.status === 200 || response.status === 201) {
                    setSnackbarSeverity('success');
                    setSnackbarMessage("Staff created successfully");
                    setOpen(false);
                    formik.resetForm();
                    window.location.reload();
                }
            } catch (error) {
                let errorMessage = "Failed to create staff. Please try again.";

                // Check for non_field_errors
                if (error.response?.data?.non_field_errors) {
                    errorMessage = error.response.data.non_field_errors[0];
                } else if (error.response?.data?.message) {
                    errorMessage = error.response.data.message;
                }

                setSnackbarSeverity('error');
                setSnackbarMessage(errorMessage);
            } finally {
                setSnackbarOpen(true);
            }
        },
    });

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={style}>
                <form onSubmit={formik.handleSubmit}>
                    <Typography variant="h4" gutterBottom>Create new user</Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                name="firstName"
                                variant="outlined"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                name="lastName"
                                variant="outlined"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                variant="outlined"
                                type="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Phone Number"
                                name="phone"
                                variant="outlined"
                                value={formik.values.phone.replace(/^0/, '')} // Remove leading zero in value
                                onChange={(e) => {
                                    let phoneNumber = e.target.value;

                                    // Remove leading zero if it exists
                                    if (phoneNumber.startsWith('0')) {
                                        phoneNumber = phoneNumber.substring(1);
                                    }

                                    formik.setFieldValue('phone', phoneNumber);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +254
                                        </InputAdornment>
                                    ),
                                }}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                label="Staff Type"
                                name="staffType"
                                variant="outlined"
                                value={formik.values.staffType}
                                onChange={formik.handleChange}
                                error={formik.touched.staffType && Boolean(formik.errors.staffType)}
                                helperText={formik.touched.staffType && formik.errors.staffType}
                            >
                                {staffTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                label="Status"
                                name="status"
                                variant="outlined"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                helperText={formik.touched.status && formik.errors.status}
                            >
                                {statusOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Password"
                                name="password"
                                type="password"
                                variant="outlined"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ backgroundColor: "#77c0a3", borderRadius: 10 }}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ backgroundColor: "#3E3E65C2", borderRadius: 10 }}
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    onClose={() => setSnackbarOpen(false)}
                >
                    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Modal>
    );
}
