import React from "react";
import { useForm } from "react-hook-form";
import API from "../../api/api";
import { Alert, Box, Button, Grid, Snackbar, TextField, Typography } from "@mui/material";

export default function VerifyCode() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('success');
    const [isCodeVerified, setIsCodeVerified] = React.useState(false);
    const [resetCode, setResetCode] = React.useState('');

    const onSubmitVerify = data => {
        API.post('/account/verify_reset_code/', data)
            .then(response => {
                if (response.status === 200) {
                    setAlertMessage("Code is correct. Please enter your new password");
                    setAlertSeverity('success');
                    setOpenAlert(true);
                    setResetCode(data.reset_code);
                    setIsCodeVerified(true);
                }
            })
            .catch(error => {
                console.log(error);
                setAlertMessage("Invalid code. Please check your email and try again");
                setAlertSeverity('error');
                setOpenAlert(true);
            });
    };

    const onSubmitResetPassword = data => {
        const resetData = { ...data, reset_code: resetCode };
        API.put('/account/create_new_password/', resetData)
            .then(response => {
                if (response.status === 200) {
                    setAlertMessage("Password has been successfully reset.");
                    setAlertSeverity('success');
                    setOpenAlert(true);
                    // Redirect to login page
                    window.location.href = '/login';
                }
            })
            .catch(error => {
                console.log(error);
                setAlertMessage("Failed to reset password. Please try again.");
                setAlertSeverity('error');
                setOpenAlert(true);
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    return (
        <Grid container
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + "/images/pages/stethoscope-graphics.jpg"})`,
                  height: "100vh",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
              }}
              sx={{ minHeight: '100vh' }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 1,
                    padding: 4,
                    margin: 2,
                    maxHeight: 500,
                    boxShadow: 3,
                }}
            >
                {!isCodeVerified ? (
                    <form onSubmit={handleSubmit(onSubmitVerify)}>
                        <Typography variant={"h4"}>Verify Code</Typography>
                        <TextField
                            margin={"normal"}
                            fullWidth
                            label={"Code"}
                            {...register('reset_code', { required: true })}
                            error={!!errors.reset_code}
                            helperText={errors.reset_code ? "Code is required" : ""}
                        />
                        <Button
                            type={"submit"}
                            variant={"contained"}
                            color={"primary"}
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            Verify
                        </Button>
                    </form>
                ) : (
                    <form onSubmit={handleSubmit(onSubmitResetPassword)}>
                        <Typography variant={"h4"}>Reset Password</Typography>
                        <TextField
                            margin={"normal"}
                            fullWidth
                            label={"New Password"}
                            type="password"
                            {...register('password', { required: true })}
                            error={!!errors.password}
                            helperText={errors.password ? "Password is required" : ""}
                        />
                        <TextField
                            margin={"normal"}
                            fullWidth
                            label={"Confirm Password"}
                            type="password"
                            {...register('confirm_password', { required: true })}
                            error={!!errors.confirm_password}
                            helperText={errors.confirm_password ? "Confirm password is required" : ""}
                        />
                        <Button
                            type={"submit"}
                            variant={"contained"}
                            color={"primary"}
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            Reset Password
                        </Button>
                    </form>
                )}
            </Box>
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleClose}
                    severity={alertSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
}
