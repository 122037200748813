import React, { useState } from 'react';
import { Box, Button, Divider, Modal, TextField, Typography, Snackbar } from '@mui/material';
import API from "../../../api/api";

function updateTestStatus(test_id, handleClose, setSnackbarMessage, setSnackbarOpen) {
    API.patch(`${process.env.REACT_APP_URL}laboratory/medicaltests/update_test_status/`, {
        "institution_id": localStorage.getItem("institution_id"),
        "test_id": test_id,
        "status": "completed"
    })
        .then((res) => {
            console.log(res);
            if (res.status === 200) {
                setSnackbarMessage("Test status updated successfully");
                setSnackbarOpen(true); // Trigger the Snackbar
                handleClose();
            } else {
                setSnackbarMessage("Failed to update test status");
                setSnackbarOpen(true); // Trigger the Snackbar with error message
            }
        })
        .catch((err) => {
            console.log(err);
            setSnackbarMessage("An error occurred while updating the test status");
            setSnackbarOpen(true); // Trigger the Snackbar with error message
        });
}

const ReviewModal = ({ test_type, handleClose, openRev }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
    const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

    let url = process.env.REACT_APP_URL;
    if (url[url.length - 1] === '/') {
        url = url.slice(0, -1);
    }

    let attachment = test_type.attachments;
    if (attachment) {
        attachment = attachment.slice(4); // Remove "api/" from the attachment URL
    }

    return (
        <div>
            <Modal
                open={openRev}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                    overflow: 'scroll',
                }}
            >
                <Box
                    style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        transform: 'translate(-50%, -50%)',
                        top: '50%',
                        left: '50%',
                        border: '1px solid #000',
                        width: "1050px",
                        height: "500px",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 10,
                        padding: 5,
                        overflow: 'auto',
                    }}
                >
                    <div style={{ padding: 24, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6">Test Details</Typography>
                        <TextField
                            label="Test Type"
                            value={test_type.test_type}
                            margin="normal"
                            fullWidth
                            disabled
                        />
                        <TextField
                            label="Test Name"
                            value={test_type.test_name}
                            margin="normal"
                            fullWidth
                            disabled
                        />

                        <Divider sx={{ mb: 2, borderTopWidth: 2 }} />
                        <Typography sx={{ textDecoration: "underline" }} variant="h6">Test Results</Typography>
                        <Typography
                            variant="body1"
                            sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}
                        >
                            {test_type.results}
                        </Typography>

                        <Divider sx={{ mb: 2, borderTopWidth: 2 }} />
                        {test_type.attachments ? (
                            <img
                                src={`${url}${attachment}`}
                                alt={"Attachment"}
                                loading="lazy"
                                style={{ maxWidth: "100%", maxHeight: "300px" }}
                            />
                        ) : (
                            <Typography>No Additional Attachment</Typography>
                        )}

                        <div>
                            <br />
                            <Button
                                variant="contained"
                                onClick={() => {
                                    updateTestStatus(test_type.id, handleClose, setSnackbarMessage, setSnackbarOpen);
                                }}
                            >
                                Done reviewing
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Snackbar for feedback */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </div>
    );
};

export default ReviewModal;
