import React, {useState} from "react";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import {NavLink, Outlet, useNavigate} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

import SideMenu from "./SideMenu";
import SearchPatient from "./SearchPatient";
import {Logout, Settings} from "@mui/icons-material";
import API from "../api/api";
import SearchIcon from "@mui/icons-material/Search";

function handleLogout() {

    API.post("logout/user/", {
        "token": localStorage.getItem("auth_token"),
        "email": localStorage.getItem("user_email"),
    })
        .then((response) => {
            console.log(response.request.data);
            localStorage.removeItem("token");
            localStorage.clear();
            window.location.reload();
        })
        .catch((error) => {
            console.log("Error");
            console.log(error);
            localStorage.clear();
            window.location.reload();
        });
}

const UseStyles = makeStyles({
    page: {
        backgroundColor: "#E5E5E5",
        // position:"fixed",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        maxHeight: "100vh"
    },
    root: {
        display: "flex",
        position: "absolute",
        width: "100%",
        margin: 0,
        padding: 0,
        height: "100%",
    },
});

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);


export default function MainLayout() {
    const navigate = useNavigate();
    const classes = UseStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [searchOpen, setSearchOpen] = useState(false)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleSearchOpen = () => {
    //     setSearchOpen(true);
    // };
    //
    // const handleSearchClose = () => {
    //     setSearchOpen(false);
    // };
    let first_name = capitalize(localStorage.getItem("user_first_name"));
    let last_name = capitalize(localStorage.getItem("user_last_name"));

    let user_type = localStorage.getItem("user_type");
    return (
        <div className={classes.root}>
            {/*Sidebar*/}
            <Container
                style={{
                    maxWidth: "250px",
                    backgroundColor: "#E5E5E5",
                    // height: '100%',
                    padding: 0,
                    margin: 0,
                }}
            >
                <div>
                    <img
                        src={"/images/afyasasa-main-logo.png"}
                        alt={"AfyaSasa Logo"}
                        width={236.05}
                    />
                </div>
                <SideMenu/>
            </Container>

            <div className={classes.page}>
                {/*Header*/}
                <Grid
                    container
                    justifyContent={"space-between"}
                    style={{
                        backgroundColor: "white",
                        marginTop: 5,
                        borderRadius: 20,
                        padding: 7,
                    }}
                >
                    <Grid
                        item
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <Button
                            // variant={'outlined'}
                            startIcon={<AddIcon color={"action"}/>}
                            style={{
                                backgroundColor: "#77c0a3",
                                borderRadius: 10,
                                alignItems: "center",
                            }}
                            onClick={() => navigate("register")}
                        >
                            <Typography variant={"body2"} color={"white"}>
                                Add Patient
                            </Typography>
                        </Button>
                        <Button
                            // variant={'outlined'}
                            startIcon={<SearchIcon color={"action"}/>}
                            style={{
                                backgroundColor: "#77c0a3",
                                borderRadius: 10,
                                alignItems: "center",
                                marginLeft: 10,
                            }}
                            onClick={() => setSearchOpen(true)}
                        >
                            <Typography variant={"body2"} color={"white"}>
                                Search Patient
                            </Typography>
                            <SearchPatient open={searchOpen}
                                           setSearchOpen={setSearchOpen}
                            />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Card
                            style={{
                                display: "flex",
                                alignItems: "center",
                                maxHeight: 50,
                            }}
                        >
                            <Tooltip title={"Account Settings"}>
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ml: 2}}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar
                                        alt={first_name + " " + last_name}
                                        src={"/images/user/01.jpg"}
                                        style={{
                                            marginTop: 5,
                                            marginRight: 5,
                                            backgroundColor: "#77c0a3",
                                        }}
                                    >VN</Avatar>
                                </IconButton>
                            </Tooltip>
                            <CardContent>
                                <Typography align={"right"}>
                                    {first_name} {last_name}
                                </Typography>
                                <Typography variant={"caption"} color={"#77c0a3"}>
                                    {user_type}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem>
                                <Avatar/>
                                <NavLink to={"/account"}
                                         style={{
                                             textDecoration: "none",
                                             color: "black"
                                         }}
                                >
                                    My account
                                </NavLink>
                            </MenuItem>
                            <Divider/>
                            <MenuItem>
                                <ListItemIcon>
                                    <Settings fontSize="small"/>
                                </ListItemIcon>
                                Settings
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>

                {/*Main Content*/}
                <div style={{
                    marginTop: 10,
                }}>
                    <Outlet/>
                </div>
            </div>
            {/*Footer*/}
        </div>
    );
}
