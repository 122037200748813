import React, {useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import API from "../../../api/api";


function MedicinePrescription({patient, medication, instruction, setMeds, setInstruction}) {
    const [issuedMedicine, setIssuedMedicine] = useState([])

    const issueMedicine = (event) => {
        // Creates an order for tests in the medicaltests endpoint
        setMeds(event.target.value)
    }
    const issueInstruction = (event) => {
        // Creates an order for tests in the medicaltests endpoint
        setInstruction(event.target.value)
    }


    async function prescribeMedication(patient_id, drug: string, instructions: string) {
        let data = {
            "patient": patient_id,
            "institution": localStorage.getItem("institution_id"),
            "staff": localStorage.getItem("user_id"),
            "medicine_name": drug,
            "instructions": instructions,
        };
        try {
            const response = await API.post("pharmacy/medication/", data);
            if (response.status === 200) {
                console.log(response.data);
                setIssuedMedicine(Array.isArray(response.data) ? response.data : [response.data]);
            } else {
                console.log(response.data);
            }
        } catch (error) {
            console.error("Error posting medication data:", error.response || error.message || error);
        }

    }

    async function deleteDrug(drug, patient) {
        let data = {
            "institution": localStorage.getItem("institution_id"),
            "patient": patient,
            "staff": localStorage.getItem("user_id"),
            "drug_id": drug
        }

        API.put("pharmacy/medication/delete_issued_medication/", data).then(
            resp => {
                setIssuedMedicine(resp.data)
                console.log(resp.data)
            }
        )
    }

    return (
        <Accordion
            style={{
                borderRadius: 15,
                minWidth: 500
            }}
        >
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography>
                    Medication
                </Typography>
            </AccordionSummary>
            <hr/>
            <AccordionDetails>
                <TextField
                    id={"diagnosis"}
                    label={"Medication"}
                    variant={"outlined"}
                    multiline={"true"}
                    style={{
                        minWidth: 470
                    }}
                    value={medication}
                    onChange={issueMedicine}
                    helperText={<>
                        Drug name
                    </>
                    }
                />
                <br/>
                <TextField
                    id={"diagnosis"}
                    label={"Instructions"}
                    variant={"outlined"}
                    multiline={"true"}
                    style={{
                        minWidth: 470
                    }}
                    value={instruction}
                    onChange={issueInstruction}
                    inputProps={{
                        style: {whiteSpace: "pre-wrap"}
                    }}
                    helperText={<>
                        Instructions and other details
                    </>
                    }
                />
                <br/>
                <Button
                    style={{
                        borderRadius: 10,
                        backgroundColor: "#77c0a3",
                        color: "white",
                        marginRight: 5,
                        marginTop: 10
                    }}
                    onClick={() => {
                        prescribeMedication(`${patient}`, medication, instruction).then(r => console.log(r))

                    }
                    }
                >
                    <Typography variant={"body1"}>
                        Prescribe
                    </Typography>
                </Button>
                <TableContainer>
                    <Typography>
                        Issued Medicine
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableCell>Medication</TableCell>
                            <TableCell>Date Issued</TableCell>
                            <TableCell>Action</TableCell>
                        </TableHead>
                        <TableBody>
                            {issuedMedicine.length > 0 ? issuedMedicine.map(({id, medicine_name, created_at}) => (
                                <TableRow key={id} id={id}>
                                    <TableCell>{medicine_name}</TableCell>
                                    <TableCell>
                                        {created_at ? new Date(created_at).toISOString().split("T")[0] : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="outlined" color="warning" onClick={() => deleteDrug(id, `${patient}`).then(r => console.log(r))}>
                                            <Typography>Delete</Typography>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )) : null}

                            <Typography>
                                Previous medication
                            </Typography>
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    )
}

export default MedicinePrescription;