import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
    Button
} from "@mui/material";
import PropTypes from 'prop-types';

const ReviewTable = ({ test_type, selectCurrentTest, setOpenRev, reviewedTests }) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Test</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Report</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {test_type.map((test) => {
                        const isReviewed = reviewedTests.includes(test.id); // Use shared reviewedTests

                        return (
                            <TableRow
                                key={test.id}
                                style={{
                                    backgroundColor: isReviewed ? "#d3f9d8" : "inherit", // Highlight reviewed tests
                                }}
                            >
                                <TableCell>{test.test_name}</TableCell>
                                <TableCell>{new Date(test.last_updated).toDateString()}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => selectCurrentTest(test.id)}
                                    >
                                        Review
                                    </Button>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>


            </Table>
        </TableContainer>
    );
};

ReviewTable.defaultProps = {
    test_type: [],
    selectCurrentTest: () => {},
    reviewedTests: [],
};

ReviewTable.propTypes = {
    test_type: PropTypes.array.isRequired,
    selectCurrentTest: PropTypes.func.isRequired,
    setOpenRev: PropTypes.func.isRequired,
    reviewedTests: PropTypes.array.isRequired,
};

export default ReviewTable;
