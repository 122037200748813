import React from 'react';
import {Button, Dialog} from '@mui/material';
import PatientHistory from './PatientHistory';

const HistoryModal = ({patientHistory, handleClose, openHist}) => {
    return (
        <Dialog open={openHist} onClose={handleClose} maxWidth="md" fullWidth>
            {/*// Button to close the modal*/}
            <Button variant={"contained"}
                    color={"primary"} onClick={handleClose}>Close</Button>
            <PatientHistory patientHistory={patientHistory}/>
        </Dialog>
    );
};

export default HistoryModal;
