import React, {useEffect, useState} from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import API from "../../api/api"
import {ExpandMore} from "@mui/icons-material";
import MedicinePrescription from "./components/MedicinePrescription";
import FollowUp from "./components/FollowUp";
import ReviewTable from "./components/ReviewTable";
import ReviewModal from "./components/ReviewModal";
import HistoryModal from "./components/HistoryModal";

function DoctorSession(props) {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");


    const [notes, setNotes] = useState("")
    const [lab_tests, setLabTests] = useState([])
    const [imaging_tests, setImageTests] = useState([])
    const [procedures, setProcedures] = useState([]);
    const {
        reason: reason1,
        patient_last_name: patient_last_name1,
        patient,
        patient_first_name: patient_first_name1,
        patient_phone,
        institution
    } = props.patient;
    const reviewMode = props.reviewMode
    const [reason, setReason] = useState(reason1)
    const [diagnosis, setDiagnosis] = useState("")
    const [medication, setMedication] = useState("")
    const [instruction, setInstruction] = useState("")
    const [details, setDetails] = useState("")
    const [lab, setLab] = useState("")
    const [procedure, setProcedure] = useState("")
    const [imaging, setImaging] = useState("")
    const [value, setValue] = useState(new Date(new Date()
        .toString()
        .split('GMT')[0] + ' UTC')
        .toISOString()
        .split('.')[0])
    const [appointment, setAppointment] = useState(props.appointment)
    const [reviewLabs, setReviewLabs] = useState([])
    const [reviewImaging, setReviewImaging] = useState([])
    const [reviewProcedures, setReviewProcedures] = useState([])
    const [openRev, setOpenRev] = useState(false);
    const [openHist, setOpenHist] = useState(false);
    const [currentReview, setCurrentReview] = useState({})
    const [history, setHistory] = useState({})
    const [reviewedTests, setReviewedTests] = useState([]);
    const [orderedLabTests, setOrderedLabTests] = useState([]);
    const [orderedImagingTests, setOrderedImagingTests] = useState([]);
    const [orderedProcedures, setOrderedProcedures] = useState([]);
    const [consultationReview, setConsultationReview] = useState({});


    async function createConsultation(status_progress = false) {
        let data = {
            patient,
            institution: localStorage.getItem("institution_id"),
            diagnosis,
            doctors_notes: notes,
            staff: localStorage.getItem("user_id"),
            reason,
            appointment: props.selectedAppointment || ""
        };

        if (status_progress) {
            data["status"] = "in_progress";
        }

        if (props.selectedAppointment) {
            data["appointment"] = props.selectedAppointment;
        }

        if (orderedLabTests.length > 0 || orderedImagingTests.length > 0 || orderedProcedures.length > 0) {
            // Tests have been ordered; status is in_progress
            data["status"] = "in_progress";

            if (diagnosis) {
                alert("Diagnosis should not be provided when tests are pending!");
                return;
            }

            if (!notes) {
                alert("Doctor's notes are required!");
                return;
            }
        } else {
            // No tests ordered; status is completed
            data["status"] = "completed";

            if (!diagnosis) {
                alert("Diagnosis is required when no tests are ordered!");
                return;
            }

            if (!notes) {
                alert("Doctor's notes are required!");
                return;
            }

            // Get appointment id from appointments based on the reason
            const appointment_id = props.appointment.find(
                (appointment) => appointment.reason === reason
            )?.id;

            if (appointment_id) {
                data["appointment"] = appointment_id;
            }
        }

        if (!notes) {
            alert("Doctor's notes are required!");
            return;
        }

        await API.post("consultation/", data)
            .then((resp) => {
                if (resp.status === 200) {
                    alert("Consultation concluded successfully");
                }
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
    }


    async function requestLab(names, type, patient) {
        const testNames = Array.isArray(names) ? names : [names];

        const requests = testNames.map((name) => ({
            institution: localStorage.getItem("institution_id"),
            patient,
            test_name: name,
            test_type: type,
            staff: localStorage.getItem("user_id"),
        }));

        for (const data of requests) {
            await API.post("laboratory/medicaltests/", data)
                .then((resp) => {
                    if (resp.status === 201) {
                        const orderedTest = resp.data; // Test object from the response
                        if (type === "lab") {
                            setOrderedLabTests((prev) => [...prev, orderedTest]);
                            setLab(""); // Clear input
                        } else if (type === "imaging") {
                            setOrderedImagingTests((prev) => [...prev, orderedTest]);
                            setImaging(""); // Clear input
                        } else if (type === "procedure") {
                            setOrderedProcedures((prev) => [...prev, orderedTest]);
                            setProcedure(""); // Clear input
                        }
                    }
                })
                .catch((e) => {
                    alert(e.response?.data?.test_name?.[0] || "Error ordering tests");
                });
        }
    }


    async function cancelTest(test, type, testId) {
        const data = {
            institution: localStorage.getItem("institution_id"),
            test_id: testId, // Use the test's id
            status: "canceled",
            patient: patient,
            staff: localStorage.getItem("user_id"),
        };
        // console.log(data)

        await API.patch(`laboratory/medicaltests/${testId}/`, data)
            .then(() => {
                if (type === "lab") {
                    setOrderedLabTests((prev) => prev.filter((t) => t.id !== test.id));
                } else if (type === "imaging") {
                    setOrderedImagingTests((prev) => prev.filter((t) => t.id !== test.id));
                } else if (type === "procedure") {
                    setOrderedProcedures((prev) => prev.filter((t) => t.id !== test.id));
                }
            })
            .catch((e) => {
                console.log(e);
                alert("Error canceling test");
            });
    }

    // Check if we are in lab review mode
    const isLabReviewMode = props.labReviewMode;

    const institution_id = localStorage.getItem("institution_id");
    useEffect(() => {
        API.get(`consultation/get_patient_consultation/?patient_id=${patient}&institution_id=${institution_id}&status=in_progress`)
            .then((resp) => {
                if (resp.data) {
                    setConsultationReview(resp.data);
                    console.log(resp.data)
                } else {
                    console.log("No consultations with status 'in_progress' found.");
                }
            })
            .catch((e) => console.log(e));
    }, []);

    // }


    const handleReviewModalOpen = () => {
        setOpenRev(true);
    };
    const handleHistoryModalOpen = () => {
        setOpenHist(true);
    };
    const handleHistoryModalClose = () => {
        setOpenHist(false);
    };


    useEffect(() => {
        API.put('laboratory/medicaltests/get_pending_review_tests_by_patient/', {
            "patient_id": patient
        })
            .then(resp => {
                let lab_tests = []
                let imaging_tests = []
                let procedures = []
                for (let i = 0; i < resp.data.length; i++) {
                    if (resp.data[i].test_type === "lab") {
                        lab_tests.push(resp.data[i])
                    }
                    if (resp.data[i].test_type === "imaging") {
                        imaging_tests.push(resp.data[i])
                    }
                    if (resp.data[i].test_type === "procedure") {
                        procedures.push(resp.data[i])
                    }
                }
                setReviewLabs(lab_tests)
                setReviewImaging(imaging_tests)
                setReviewProcedures(procedures)

            })
            .catch(e => console.log(e))

    }, [])

    // TODO: Turn this into a function that can be called when the doctor needs to see the patient's history
    function get_patient_history(patient) {
        API.get("consultation/get_patient_history/", {
            params: {
                patient_id: patient,
                institution: localStorage.getItem("institution_id"),
                staff_id: localStorage.getItem("user_id")
            }
        })
            .then(resp => {
                setHistory(resp.data)
                // check if resp.data has any data
                if (resp.data) {
                    // if it does, then open the modal
                    handleHistoryModalOpen()
                } else {
                    // if it doesn't, then alert the doctor that the patient has no history
                    alert("Patient has no history")
                }

            })
            .catch(e => console.log(e))
    }


    const changeReason = (event) => {
        // TODO: Create a mechanism that allows for persistence of data in case of a refresh or accidental close
        //  of webpage.
        setReason(event.target.value)
    }
    const takeNotes = (event) => {
        // TODO: Create a mechanism that allows for persistence of data in case of a refresh or accidental close
        //  of webpage.
        setNotes(event.target.value)
    }
    const writeDiagnosis = (event) => {
        // TODO: Create a mechanism that allows for persistence of data in case of a refresh or accidental close
        //        of webpage.

        setDiagnosis(event.target.value)
    }
    const writeDetails = (event) => {
        // TODO: Create a mechanism that allows for persistence of data in case of a refresh or accidental close
        //        of webpage.
        setDetails(event.target.value)
    }
    const writeDate = (event) => {
        // TODO: Create a mechanism that allows for persistence of data in case of a refresh or accidental close
        //        of webpage.
        setValue(event.target.value)
    }
    const orderImaging = (event) => {
        // Creates an order for tests in the medicaltests endpoint
        setImaging(event.target.value)
    }

    function selectCurrentTest(test_id) {
        const labTest = reviewLabs.find((test) => test.id === test_id);
        const imagingTest = reviewImaging.find((test) => test.id === test_id);
        const procedureTest = reviewProcedures.find((test) => test.id === test_id);

        if (labTest) {
            setCurrentReview(labTest);
            setReviewedTests((prev) => (prev.includes(test_id) ? prev : [...prev, test_id]));
            setSnackbarMessage("Lab test marked as reviewed");
        } else if (imagingTest) {
            setCurrentReview(imagingTest);
            setReviewedTests((prev) => (prev.includes(test_id) ? prev : [...prev, test_id]));
            setSnackbarMessage("Imaging test marked as reviewed");
        } else if (procedureTest) {
            setCurrentReview(procedureTest);
            setReviewedTests((prev) => (prev.includes(test_id) ? prev : [...prev, test_id]));
            setSnackbarMessage("Procedure marked as reviewed");
        }

        setSnackbarOpen(true);
        setOpenRev(true);
    }


    async function updateNotesInReview(newNotes: string) {
        let data = {
            "doctors_notes": newNotes
        }
        await API.put(`consultation/${props.recentConsultation.id}/`, data)
            .then(resp => {
                if (resp.status === 200) {
                    alert("Notes updated successfully")
                    window.location.reload()
                }
            })
            .catch(e => {
                console.log(e)
            })
    }


    useEffect(() => {
            API.get("laboratory/medicaltests/tests_options/")
                .then((resp) => {
                        setLabTests(resp.data["lab-tests"])
                        setImageTests(resp.data["imaging-tests"])
                    }
                ).catch((e) => {
                console.log(e)
            })
        }, []
    )

    useEffect(() => {
        if (reviewMode && props.recentConsultation) {
            console.log("Review mode for patient: ", props.recentConsultation);
            const consultation = props.recentConsultation;
            setNotes(consultation.doctors_notes);
            setDiagnosis(consultation.diagnosis);
            setReason(consultation.reason_for_visit);
        }
    }, [reviewMode, props.recentConsultation]);
    return (
        <Grid
            container
            rowSpacing={2}
        >
            <Grid item
                  container
                  spacing={2}
                  justifyContent={"space-evenly"}
            >
                <Grid item>
                    <Card style={{
                        minWidth: 500,
                        borderRadius: 15,
                        // padding: 5
                    }}>
                        <Typography style={{padding: 10}}>
                            Patient Details
                        </Typography>
                        <CardMedia>
                            <img src={"/images/user/1.jpg"} alt={"user Img"}/>
                        </CardMedia>
                        <CardContent>
                            <Typography variant={"h6"}>
                                Name: {patient_first_name1} <span>{patient_last_name1} </span>
                                <br/>
                                Phone: {patient_phone}
                            </Typography>
                            <Typography
                                variant={"h4"}
                                style={{
                                    color: isLabReviewMode ? 'green' : reviewMode ? 'orange' : 'blue',
                                }}

                            >
                                {isLabReviewMode
                                    ? "Lab Review Mode"
                                    : reviewMode
                                        ? "Review Mode"
                                        : "Consultation Mode"}

                            </Typography>

                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => get_patient_history(patient)}
                            >Get history
                            </Button>
                        </CardContent>
                    </Card>
                    <HistoryModal patientHistory={history}
                                  handleOpen={handleHistoryModalOpen}
                                  handleClose={handleHistoryModalClose}
                                  openHist={openHist}
                    />
                </Grid>
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Appointment List
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableCell>Date & Time</TableCell>
                                        <TableCell>Patient</TableCell>
                                        <TableCell>Issue</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {props.appointment?.map(({
                                                                     appointment_time,
                                                                     id,
                                                                     patient_first_name,
                                                                     patient_last_name,
                                                                     reason
                                                                 }) => (
                                            <TableRow
                                                key={id}
                                                id={id}
                                                hover
                                            >
                                                <TableCell>{new Date(appointment_time)
                                                    .toLocaleTimeString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true
                                                    })}</TableCell>
                                                <TableCell>{patient_first_name} {patient_last_name}</TableCell>
                                                <TableCell>{reason}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid item
                  container
                  spacing={2}
                  justifyContent={"space-evenly"}
            >
                <Grid item
                >
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Reason for Visit
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{
                            borderBottomRightRadius: 15,
                            borderBottomLeftRadius: 15
                        }}>
                            <TextField
                                id={"procedure"}
                                label={"Reason"}
                                variant={"outlined"}
                                multiline={"true"}
                                style={{
                                    minWidth: 470,
                                    paddingBottom: 15
                                }}
                                value={reason}
                                onChange={changeReason}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item>
                    <Accordion style={{
                        borderRadius: 15,
                        minWidth: 500
                    }}
                               defaultExpanded={true}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Notes
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <TextField
                                id={"notes"}
                                label={"Doctor's notes"}
                                variant={"outlined"}
                                multiline={"true"}
                                style={{
                                    minWidth: 470
                                }}
                                value={notes}
                                onChange={takeNotes}
                                inputProps={{
                                    style: {whiteSpace: "pre-wrap"}
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid item
                  container
                  spacing={2}
                  justifyContent={"space-evenly"}
            >
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Lab Tests
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <Autocomplete
                                disablePortal
                                multiple
                                filterSelectedOptions
                                id="labs"
                                options={Array.from(lab_tests)}
                                sx={{width: 300}}
                                onChange={(event, value) => setLab(value)}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => (
                                    <TextField {...params} label="Lab tests"
                                               value={lab}
                                               onChange={(event) => setLab(event.target.value)} // Update `lab` on input change

                                    />)}
                            />
                            <br/>
                            <Button
                                style={{
                                    borderRadius: 10,
                                    backgroundColor: "#77c0a3",
                                    color: "white",
                                    marginRight: 5,
                                    marginTop: 10
                                }}
                                onClick={() => {
                                    requestLab(lab, "lab", patient);
                                    setLab([]); // Clear the `lab` state to reset the input
                                }}>Order</Button>
                            <div>
                                <Typography variant="h6">Ordered Lab Tests</Typography>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Test Name</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderedLabTests.map((test) => (
                                                <TableRow key={test.id}>
                                                    <TableCell>{test.test_name}</TableCell>
                                                    <TableCell>{test.test_type}</TableCell>
                                                    <TableCell>{test.status}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            color="error"
                                                            onClick={() => cancelTest(test, "lab", test.id)}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>

                            <ReviewTable test_type={reviewLabs}
                                         selectCurrentTest={selectCurrentTest}
                                         setOpenRev={setOpenRev}
                                         reviewedTests={reviewedTests} // Pass reviewed tests
                            />
                            <ReviewModal test_type={currentReview}
                                         handleOpen={handleReviewModalOpen}
                                         handleClose={() => {
                                             setOpenRev(false); // Close the modal
                                             setSnackbarMessage("Test marked as reviewed");
                                             setSnackbarOpen(true); // Trigger Snackbar after modal closes
                                         }}

                                         openRev={openRev}
                            />

                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Imaging
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <Autocomplete
                                id={"imaging"}
                                disablePortal
                                multiple
                                filterSelectedOptions
                                options={Array.from(imaging_tests)}
                                sx={{width: 300}}
                                onChange={(event, value) => setImaging(value)}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) =>
                                    <TextField {...params} label="Imaging tests"
                                               value={imaging}
                                               onChange={orderImaging}
                                    />}
                            />
                            <br/>
                            <Button
                                style={{
                                    borderRadius: 10,
                                    backgroundColor: "#77c0a3",
                                    color: "white",
                                    marginRight: 5,
                                    marginTop: 10
                                }}
                                onClick={() => {
                                    requestLab(imaging, 'imaging', patient);
                                }}>Order</Button>
                            <Typography variant="h6">Ordered Imaging Tests</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Test Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderedImagingTests.map((test) => (
                                            <TableRow key={test.id}>
                                                <TableCell>{test.test_name}</TableCell>
                                                <TableCell>{test.status}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        color="error"
                                                        onClick={() => cancelTest(test, "imaging", test.id)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <ReviewTable
                                test_type={reviewImaging}
                                selectCurrentTest={selectCurrentTest}
                                setOpenRev={setOpenRev}
                                reviewedTests={reviewedTests} // Pass reviewed tests
                            />

                            <ReviewModal test_type={currentReview}
                                         handleOpen={handleReviewModalOpen}
                                         handleClose={() => {
                                             setOpenRev(false); // Close the modal
                                             setSnackbarMessage("Test marked as reviewed");
                                             setSnackbarOpen(true); // Trigger Snackbar after modal closes
                                         }}

                                         openRev={openRev}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid item
                  container
                  spacing={2}
                  justifyContent={"space-evenly"}
            >
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Procedures
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <TextField
                                id="procedure"
                                label="New Procedure"
                                variant="outlined"
                                multiline
                                style={{
                                    minWidth: 470,
                                    paddingBottom: 15
                                }}
                                value={procedure} // Bind the state variable for the input
                                onChange={(event) => setProcedure(event.target.value)} // Correctly update the procedure state
                            />
                            <br/>
                            <Button
                                style={{
                                    borderRadius: 10,
                                    backgroundColor: "#77c0a3",
                                    color: "white",
                                    marginRight: 5,
                                    marginTop: 10
                                }}
                                onClick={() => {
                                    if (procedure.trim()) { // Ensure the input is not empty
                                        requestLab(procedure, 'procedure', patient);
                                    } else {
                                        alert("Please enter a procedure before ordering.");
                                    }
                                }}
                            >
                                Order
                            </Button>

                            <Typography variant="h6">Ordered Procedures</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Procedure Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderedProcedures.map((test) => (
                                            <TableRow key={test.id}>
                                                <TableCell>{test.test_name}</TableCell>
                                                <TableCell>{test.status}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        color="error"
                                                        onClick={() => {
                                                            cancelTest(test, "procedure", test.id);
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <ReviewTable
                                test_type={reviewProcedures}
                                selectCurrentTest={selectCurrentTest}
                                setOpenRev={setOpenRev}
                                reviewedTests={reviewedTests} // Pass reviewed tests
                            />

                            <ReviewModal test_type={currentReview}
                                         handleOpen={handleReviewModalOpen}
                                         handleClose={() => {
                                             setOpenRev(false); // Close the modal
                                             setSnackbarMessage("Test marked as reviewed");
                                             setSnackbarOpen(true); // Trigger Snackbar after modal closes
                                         }}

                                         openRev={openRev}
                            />
                            <Snackbar
                                open={snackbarOpen}
                                autoHideDuration={3000}
                                onClose={() => setSnackbarOpen(false)}
                                message={snackbarMessage}
                            />

                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item>
                    <MedicinePrescription patient={props.patient.patient} medication={medication}
                                          instruction={instruction}
                                          setMeds={setMedication} setInstruction={setInstruction}/>
                </Grid>
            </Grid>
            <Grid item
                  container
                  spacing={2}
                  justifyContent={"space-evenly"}
            >
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                        defaultExpanded={true}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Diagnosis
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                id={"diagnosis"}
                                label={"Diagnosis"}
                                variant={"outlined"}
                                multiline={"true"}
                                style={{
                                    minWidth: 470
                                }}
                                value={diagnosis}
                                onChange={writeDiagnosis}
                            />

                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item>
                    <FollowUp patient_fname={patient_first_name1} details={details} writeDetails={writeDetails}
                              value={value} patient={patient} institution={institution} writeDate={writeDate}
                              reviewMode={reviewMode}/>
                </Grid>
            </Grid>
            <Grid
                item
                container
                spacing={2}
                justifyContent={"space-around"}
                style={{
                    marginBottom: 20
                }}
            >

                <Button
                    style={{
                        borderRadius: 10,
                        backgroundColor: "#77c0a3",
                        color: "white",
                        marginRight: 5,
                        marginTop: 10,
                    }}
                    onClick={() => {
                        if (isLabReviewMode) {
                            console.log("Diagnosis:", diagnosis);
                            console.log("Notes:", notes);
                            console.log("Lab tests:", orderedLabTests);
                            console.log("Imaging tests:", orderedImagingTests);
                            console.log("Procedures:", orderedProcedures);

                            /// Determine the consultation status
                            const status =
                                orderedLabTests.length > 0 || orderedImagingTests.length > 0 || orderedProcedures.length > 0
                                    ? "in_progress" // Tests are still pending
                                    : diagnosis && consultationReview.status === "in_progress"
                                        ? "completed" // Diagnosis provided and no more tests ordered
                                        : consultationReview.status; // Retain current status if nothing changes
                            if (diagnosis || notes) {

                                // Update diagnosis, notes, and status in lab review mode
                                API.put(`consultation/${consultationReview.id}/`, {
                                    diagnosis: diagnosis || consultationReview.diagnosis,
                                    doctors_notes: notes || consultationReview.doctors_notes,
                                    status: status,
                                })
                                    .then((r) => {
                                        console.log("Diagnosis, notes, and status updated");
                                        alert("Updates saved successfully.");
                                        window.location.reload();
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        alert("Failed to update diagnosis or notes.");
                                    });
                            } else if (status === "completed" && !diagnosis) {
                                alert("Diagnosis is required to conclude a consultation!");
                            } else {
                                window.location.reload()
                            }
                        } else if (reviewMode) {
                            // Handle regular review mode
                            updateNotesInReview(notes).then((r) => console.log("Notes updated"));
                        } else {
                            // Handle consultation creation
                            createConsultation(false).then((r) => {
                                console.log("Consultation created");
                            });
                        }
                    }}
                >
                    <Typography variant={"body1"}>Done with patient</Typography>
                </Button>


            </Grid>
        </Grid>


    )
}

export default DoctorSession;