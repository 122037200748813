import React from "react";
import {Box, Button, Grid, MenuItem, Modal, TextField, Typography} from "@mui/material";

const staffTypes = [
    {value: 'nurse', label: 'Nurse'},
    {value: 'doctor', label: 'Doctor'},
    {value: 'office_admin', label: 'Office Admin'},
]

export default function ViewEditUser({open, setOpen, user}) {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [staffType, setStaffType] = React.useState('');

    function editUser() {
        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "staff_type": staffType
        }
        console.log(data)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: 10,
        width: "1050px",
        height: "500px"
    };


    return (
        <Modal open={open}>
            <Box sx={style}>
                <Grid item>
                    <Typography variant={"h4"}>Create new user</Typography>
                </Grid>
                <Grid item>
                    {/*Row 1*/}
                    <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>First name</Typography>
                            <TextField type={"text"}
                                       defaultValue={user.first_name}
                                       style={{width: "100%"}}
                                       required={true}
                                       onChange={(e) => {
                                           setFirstName(e.target.value)
                                       }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>Last name</Typography>
                            <TextField type={"text"}
                                       required={true}
                                       defaultValue={user.last_name}
                                       style={{width: "100%"}}
                                       onChange={(e) => {
                                           setLastName(e.target.value)
                                       }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {/*Row 2*/}
                </Grid>
                <Grid item>
                    {/*Row 3*/}
                    <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>Staff Type</Typography>
                            <TextField
                                select
                                type={"text"}
                                defaultValue={user.staff_type}
                                style={{width: "100%"}}>
                                {staffTypes.map((option) => (
                                    <MenuItem key={option.value}
                                              value={option.value}
                                              onClick={() => {
                                                  setStaffType(option.value)
                                              }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    {/*Save and cancel buttons*/}
                    <Grid container direction={"row"} spacing={2}
                          justifyItems={"center"}
                          justifyContent={"center"}
                          style={{marginTop: "10px"}}
                    >
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: "#77c0a3",
                                    borderRadius: 10,
                                    color: "white",
                                    alignItems: "center"
                                }}
                                onClick={() => {
                                    editUser()
                                }}
                            >Save</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: "#3E3E65C2",
                                    borderRadius: 10,
                                    color: "white",
                                    alignItems: "center"
                                }}
                                onClick={() => setOpen(false)}>Close</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}