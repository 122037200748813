// Modal component for displaying a modal dialog to allow user to search for a patient
import React from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";

import API from "../api/api";
import SearchIcon from "@mui/icons-material/Search";
import {MoreVertOutlined} from "@mui/icons-material";


async function searchPatient(patientID) {
    const response = await API.get(`patient/search_by_id_number/`, {
        params: {
            id_number: patientID
        }
    });
    return response.data;
}

async function createTriage(patient_id) {
    const response = await API.post(`triage/`, {
        patient_id: patient_id,
        institution_id: localStorage.getItem("institution_id"),

    });
    return response;
}

function SearchPatientModal(props) {
    const [patientID, setPatientID] = React.useState("");
    const [patientsFound, setPatientsFound] = React.useState([]);  // Array of patients found
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [selectedIndex, setSelectedIndex] = React.useState(null);

    const handleClick = (event,) => {
        setAnchorEl(event.currentTarget);
        // setSelectedIndex(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
        // setSelectedIndex(null);
    };


    return (
        <Modal
            open={props.open}
            onClose={() => props.close}
            aria-labelledby="Search Patient"
            aria-describedby="Search for a patient"
            backdrop={true}
        >
            <Box
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    transform: 'translate(-50%, -50%)',
                    top: '50%',
                    left: '50%',
                    border: '1px solid #000',
                    width: "1050px",
                    height: "500px",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 10,
                    padding: 5,
                    overflow: 'auto',
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div>
                    <Button
                        style={{
                            backgroundColor: "#fab453",
                            borderRadius: 15,
                            color: "white",
                            alignItems: "center",
                            borderBottom: 3
                        }}
                        onClick={() => {
                            setPatientsFound([])
                            props.setSearchOpen(false);
                        }}
                    >
                        close</Button>
                    <hr/>
                    <Typography variant="h6" component="div" gutterBottom>
                        Search for a patient by ID
                    </Typography>
                    <Grid
                        container
                        direction="column"
                    >
                        <Grid item
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  padding: 5
                              }}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Search"
                                variant="outlined"
                                onChange={(e) => {
                                    setPatientID(e.target.value)
                                }}
                            />
                            <Button
                                style={{
                                    backgroundColor: "#77c0a3",
                                    borderRadius: 15,
                                    color: "white",
                                    alignItems: "center",
                                    borderBottom: 3
                                }}
                                endIcon={<SearchIcon/>}
                                onClick={() => {
                                    searchPatient(patientID).then((data) => {
                                        setPatientsFound(data);
                                    });
                                }}
                            >
                                Search</Button>
                        </Grid>
                        <Grid item>
                            <hr/>
                            <Typography variant="h6" component="div" gutterBottom>
                                Search Results
                            </Typography>
                            {patientsFound.length > 0 ? patientsFound.map((patient) => {
                                return (
                                    <Grid
                                        container
                                        direction="row"
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            padding: 5
                                        }}
                                    >
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableCell>First Name</TableCell>
                                                    <TableCell>Last Name</TableCell>
                                                    <TableCell>Patient type</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableHead>
                                                <TableRow key={patient.id}
                                                          hover
                                                >
                                                    <TableCell>{patient.first_name}</TableCell>
                                                    <TableCell>{patient.last_name}</TableCell>
                                                    <TableCell>{patient.patient_type}</TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={e => handleClick(e)}>
                                                            <MoreVertOutlined/>
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleClose}
                                                        >
                                                            <MenuItem
                                                                onClick={() => {
                                                                    createTriage(patient.id).then((data) => {
                                                                        if (data.status === 201) {
                                                                            alert("Patient sent to triage");
                                                                            setPatientsFound([]);
                                                                            props.setSearchOpen(false);
                                                                        } else {
                                                                            alert("Error sending patient to triage");
                                                                        }
                                                                    });
                                                                }}
                                                            >Send to Triage</MenuItem>
                                                            {/*<MenuItem*/}
                                                            {/*    // onClick={}*/}
                                                            {/*>*/}
                                                            {/*    Check for Medicine*/}
                                                            {/*</MenuItem>*/}
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                )
                            }) : (
                                <Typography variant="h6" component="div" gutterBottom>
                                    No patients found
                                </Typography>
                            )
                            }

                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Modal>
    );
}

export default SearchPatientModal;
