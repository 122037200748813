import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import ConsultationsTab from './tabs/ConsultationsTab';
import ImagingTab from './tabs/ImagingTab';
import LabsTab from './tabs/LabsTab';
import ProceduresTab from './tabs/ProceduresTab';
import MedicationTab from './tabs/MedicationsTab';

const PatientHistory = ({ patientHistory }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box>
            <Tabs value={activeTab} onChange={handleChange} centered>
                <Tab label="Consultations" />
                <Tab label="Imaging" />
                <Tab label="Labs" />
                <Tab label="Procedures" />
                <Tab label="Medication" />
            </Tabs>
            {activeTab === 0 && <ConsultationsTab consultations={patientHistory.consultations} />}
            {activeTab === 1 && <ImagingTab labs={patientHistory.labs.filter(item => item.test_type === 'imaging')} />}
            {activeTab === 2 && <LabsTab labs={patientHistory.labs.filter(item => item.test_type === 'lab')} />}
            {activeTab === 3 && <ProceduresTab labs={patientHistory.labs.filter(item => item.test_type === 'procedure')} />}
            {activeTab === 4 && <MedicationTab medication={patientHistory.medication} />}
        </Box>
    );
};

export default PatientHistory;
