import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Divider, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HistoryItemAccordion = ({ title, details }) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleToggle} sx={{ marginBottom: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ width: '100%' }}>
                    <Typography variant="subtitle1">Patient: {details.patient_first_name} {details.patient_last_name}</Typography>
                    <Typography variant="subtitle2">Reason for Visit: {details.reason_for_visit || 'N/A'}</Typography>
                    <Typography variant="subtitle2">Status: <span style={{ color: getStatusColor(details.status) }}>{details.status.replace(/_/g, ' ')}</span></Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2">Diagnosis: {details.diagnosis || 'N/A'}</Typography>
                    <Typography variant="body2">Doctor's Notes: {details.doctors_notes || 'N/A'}</Typography>
                    <Typography variant="body2">Created At: {new Date(details.created_at).toLocaleString()}</Typography>
                    <Typography variant="body2">Last Updated: {new Date(details.last_updated).toLocaleString()}</Typography>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

// Function to get color based on status
const getStatusColor = (status) => {
    switch (status) {
        case 'completed':
            return 'green';
        case 'pending_review':
            return 'orange';
        case 'in_progress':
            return 'blue';
        default:
            return 'black';
    }
};

export default HistoryItemAccordion;
