import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Box, Button, Grid, Modal,
    Paper, Stack, Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow, TextField, Typography
} from "@mui/material";

import Calendar from "react-calendar";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import 'react-calendar/dist/Calendar.css'
import './cal.css'
import API from "../../api/api"
import moment from "moment";

async function createAppointment(appointment_time) {
    let test_data = {
        "reason": "Test reason 5",
        "patient": "ad4b419e-750d-43c2-92b8-4f88b70be037",
        "institution": "a3060270-9a4f-4098-a365-c23144472956",
        "staff": "d7065178-5537-414f-9e2c-cd483f3a919d",
        "appointment_time": appointment_time,
        "other_details": "no other details at the moment"
    }

    await API.post("createappointment/", test_data)
        .then(resp => {
            console.log(resp.data)
        })
        .catch((e) => {
            console.log(e)
        })
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 10
};


function MyCalendar() {
    const [displayDate, setDisplayDate] = useState(new Date())
    const [appointment, setAppointment] = useState("")
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(moment().format('YYYY-MM-DDTHH:MM'));

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    // displayDate.setHours(displayDate.getHours() + 3)

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        API.get("api/appointments/")
            .then(resp => {
                setAppointment(resp.data)
                console.log(resp.data.results)
            })
    }, [])

// console.log(displayDate.toISOString())
// displayDate.setHours(displayDate.getHours() + 3)
// console.log(displayDate.toISOString())
    return (
        <>
            <Grid container
                  direction={"row"}
                  justifyContent={"space-between"}
            >
                <Grid item
                      style={{backgroundColor: "white", borderRadius: 15, padding: 10}}
                >
                    <Typography>
                        Today's appointments: {appointment.count}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 600}} size="small" aria-label="Today's appointments">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Patient</TableCell>
                                    <TableCell>Doctor</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Issue</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appointment["results"]?.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component={"th"} scope={"row"}>
                                            {row.patient_first_name} {row.patient_last_name}
                                        </TableCell>
                                        <TableCell>Dr. {row.doctor_first_name}</TableCell>
                                        <TableCell>{new Date(row.appointment_time).toLocaleTimeString('en-US')}</TableCell>
                                        <TableCell>{row.reason}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item
                      style={{backgroundColor: "white", borderRadius: 15, padding: 10}}
                >
                    <Typography>
                        This Month's appointments: 45
                    </Typography>
                    <div>
                        <Calendar onChange={setDisplayDate} value={displayDate}/>
                    </div>
                    <Grid item
                          container
                          justifyContent={"center"}
                        // style={{ backgroundColor: "white"}}
                          marginTop={2}
                    >
                        <Button
                            variant={"outlined"}
                            endIcon={<AddCircleIcon/>}
                            style={{
                                backgroundColor: "#F8B133",
                                borderRadius: 20,
                                color: "white",
                                alignItems: "center"
                            }}
                            onClick={() => {
                                // createAppointment().then(r => console.log("success"))
                                handleOpen()
                            }}
                        >
                            New Appointment
                        </Button>
                        <Modal
                            open={open}
                        >
                            <Box sx={style}>
                                <Typography variant={"h6"} marginBottom={1}>
                                    Please select the Date and time to see Mgonjwa Moja
                                </Typography>
                                <Autocomplete
                                    label={"patient"}
                                    id={"patient_select"}
                                    type={"text"}
                                    filterSelectedOptions
                                    isOptionEqualToValue={(option, value) => option === value}
                                    />
                                <Stack spacing={3}>
                                    <TextField
                                        label="Next appointment"
                                        type="datetime-local"
                                        defaultValue={value}
                                        sx={{width: 250}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Stack>
                                <Button onClick={(handleClose)}>close</Button>

                                <Button onClick={() => {
                                    createAppointment(value)
                                        .then(resp => {
                                            console.log(resp);
                                            handleClose();
                                        })
                                }}>Create Appointment</Button>
                            </Box>

                        </Modal>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default MyCalendar;