import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import API from "../../../api/api";

function TriageDataTable({ appointment, setAppointment, selectNextPatient }) {

    async function removeTriage(id) {
        // This function will remove the triage
        let data = {
            "institution_id": localStorage.getItem("institution_id"),
            "triage_id": id
        }
        await API.put(`triage/delete_triage_entry/`, data)
            .then(resp => {
                console.log(resp)
                // Remove entry from appointment list
                setAppointment(prevAppointments => prevAppointments.filter(item => item.id !== id));
            })
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 600}} size="small" aria-label="Today's appointments">
                <TableHead>
                    <TableRow>
                        <TableCell>Patient</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {appointment.length > 0 ? appointment.map(({
                                                                   created_at,
                                                                   id,
                                                                   patient_id,
                                                                   patient_first_name,
                                                                   patient_last_name,
                                                               }) => (
                        <TableRow
                            key={id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            id={id}
                            hover
                            onClick={() => {
                                selectNextPatient(patient_id)
                            }}
                        >
                            <TableCell scope={"row"}>
                                {patient_first_name} {patient_last_name}
                            </TableCell>
                            <TableCell>{new Date(created_at).toLocaleTimeString([], {
                                hour: '2-digit', minute: '2-digit', hour12: true
                            })}</TableCell>
                            <TableCell>
                                <Button
                                    variant={"contained"}
                                    color={"warning"}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevents the row click event
                                        removeTriage(id);
                                    }}>Remove
                                </Button>
                            </TableCell>
                        </TableRow>
                    )) : (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography variant={"h6"}>
                                    No data to show
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TriageDataTable;
