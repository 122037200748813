import React, {useState} from "react";
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";

function MedsList({medication, getNotes, getDrugID, deleteDrug, patient}) {
    const [selectRow, setRow] = useState(null)

    return (
        <Grid item
              style={{
                  backgroundColor: "white",
                  borderRadius: 15,
                  minWidth: 600,
                  padding: 10
              }}
        >
            <Typography variant={"h6"}>
                Medication List
            </Typography>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell>Time</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                    </TableHead>
                    <TableBody>
                        {medication?.map(({medicine_name, instructions, id, status, created_at}) =>(
                            <TableRow
                                key={id}
                                id={id}
                                hover
                                onClick={() =>{
                                    getNotes(instructions)
                                    getDrugID(id)
                                    setRow(id)
                                }}
                                selected={selectRow === id}
                                >
                                <TableCell>
                                    <span>{new Date(created_at).toISOString().split("T")[0]}</span>
                                    <span> {new Date(created_at).toLocaleTimeString('en-US')}</span>
                                </TableCell>
                                <TableCell>{medicine_name}</TableCell>
                                <TableCell>{status}</TableCell>
                                <TableCell>
                                    <Button variant={"outlined"} color={"warning"}
                                            onClick={() => {
                                                deleteDrug(id, `${patient}`)
                                                    .then(r => console.log(r))
                                            }}
                                    >
                                        <Typography>
                                            Delete
                                        </Typography>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default MedsList;