import React from "react";
import {Button, Grid, TextField} from "@mui/material";
// import {useNavigate} from "react-router-dom";

export default function VerificationForm({setView, setCode, handleFormSubmit, firstName, lastName, idNo, dob, sex,phone,
    email, guardianFirstName, guardianLastName, isChildAccount,
    patientType}){
    return (
        <Grid container
                direction="row"
                // justifyContent="space-between"
                style={{
                    backgroundColor: "white",
                    maxWidth: "96%",
                    paddingLeft: "10px",
                    marginLeft: "20px",
                    borderRadius: 5,
                    paddingBottom: 10,
                    paddingTop: 10
        }}
        >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                    id="outlined-basic"
                    label="Verification Code"
                    variant="outlined"
                    style={{
                        width: "95%",
                        marginBottom: 10
                    }}
                    onChange={(e) => setCode(e.target.value)}
                />
            </Grid>
            <Grid item container
                    direction="row"
                    justifyContent={"flex-end"}
                    xs={12} sm={12} md={12} lg={12} xl={12}
            >
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#FFC107",
                        color: "white",
                        minWidth: "10%"
                    }}
                    onClick={() => setView("register")}
                >Back</Button>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#78C0A3",
                        color: "white",
                        minWidth: "10%",
                        marginLeft: 10
                    }}
                    onClick={() => {
                        handleFormSubmit({firstName, lastName, idNo, dob, sex,phone,
                            email, guardianFirstName, guardianLastName, isChildAccount,
                            patientType})
                        // alert("Accessed")
                    }}
                >Verify
                </Button>
            </Grid>
        </Grid>
    )
}