import React, { useEffect, useState } from "react";
import { Alert, Button, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { CheckCircleOutlineOutlined, InfoOutlined } from "@mui/icons-material";
import api from "../../api/api";

const FormField = ({ label, value, name, onChange, error }) => (
    <Grid container item xs={12} spacing={2} alignItems="center">
        <Grid item xs={12} sm={3} md={2}>
            <Typography variant="h6">{label}</Typography>
        </Grid>
        <Grid item>
            <TextField
                name={name}
                value={value || ''}
                onChange={onChange}
                style={{ width: '300px' }}
                margin="normal"
                error={error}
                helperText={error && (name === 'email' ? "Invalid email address" : "Phone number must start with +254 and have 9 digits")}
            />
        </Grid>
    </Grid>
);

const Account = () => {
    const [profileData, setProfileData] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    // const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    // const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [changesMade, setChangesMade] = useState(false); // Track if changes were made

    useEffect(() => {
        api.get('account/').then(resp => {
            setProfileData(resp.data);
            setFirstName(resp.data.first_name);
            setLastName(resp.data.last_name);
            // setEmail(resp.data.email);
            setPhone(resp.data.phone);
        });
    }, []);

    // const validateEmail = (email) => {
    //     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     return re.test(String(email).toLowerCase());
    // };

    const validatePhoneNumber = (phone) => {
        const re = /^\+254\d{9}$/;
        return re.test(String(phone).toLowerCase());
    };

    // const handleEmailChange = (event) => {
    //     const newEmail = event.target.value;
    //     setEmail(newEmail);
    //     setEmailError(!validateEmail(newEmail));
    //     setChangesMade(true); // Email change counts as a change
    // };

    const handlePhoneChange = (event) => {
        const newPhone = event.target.value;
        setPhone(newPhone);
        setPhoneError(!validatePhoneNumber(newPhone));
        setChangesMade(true); // Phone change counts as a change
    };

    const handleFirstNameChange = (event) => {
        const newFirstName = event.target.value;
        setFirstName(newFirstName);
        setChangesMade(true); // First name change counts as a change
    };

    const handleLastNameChange = (event) => {
        const newLastName = event.target.value;
        setLastName(newLastName);
        setChangesMade(true); // Last name change counts as a change
    };


    const handleUpdate = () => {
        // if (emailError || phoneError) {
        //     return;
        // }
        if (phoneError) {
            return;
        }

        const updatedData = {};

        if (firstName !== profileData.first_name) {
            updatedData.first_name = firstName;
        }

        if (lastName !== profileData.last_name) {
            updatedData.last_name = lastName;
        }

        // if (email !== profileData.email) {
        //     updatedData.email = email;
        // }

        if (phone !== profileData.phone) {
            updatedData.phone = phone;
        }

        // Send only the updated data
        if (Object.keys(updatedData).length > 0) {
            setChangesMade(true);
            api.patch(`account/${localStorage.getItem("user_id")}/`, updatedData)
                .then(resp => {

                    localStorage.setItem('user_first_name', resp.data.first_name);
                    localStorage.setItem('user_last_name', resp.data.last_name);

                    setShowSnackbar(true);

                })
                .catch(err => {
                    console.error('Error updating profile:', err);
                    // Handle errors
                });
        } else {
            setShowSnackbar(true); // Show Snackbar when no changes detected
        }
    };

    return (
        <>
            <Grid container direction="column"
                  justifyContent={"center"}
                  alignContent={"center"}
                  alignItems={"center"}
                  spacing={3} style={{ padding: 20, margin: 10 }}>
                <FormField
                    label="First Name"
                    name="first_name"
                    value={firstName}
                    onChange={handleFirstNameChange}
                />
                <FormField
                    label="Last Name"
                    name="last_name"
                    value={lastName}
                    onChange={handleLastNameChange}
                />

                {/*<FormField*/}
                {/*    label="Email"*/}
                {/*    name="email"*/}
                {/*    value={email}*/}
                {/*    onChange={handleEmailChange}*/}
                {/*    error={emailError}*/}
                {/*/>*/}
                <FormField
                    label="Phone"
                    name="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    error={phoneError}
                />
                <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '600px' }}
                    onClick={handleUpdate}
                >
                    Update Profile
                </Button>
            </Grid>
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
                <Alert onClose={() => {
                    setShowSnackbar(false);
                    if (changesMade) {
                        localStorage.setItem("user_first_name", firstName);
                        localStorage.setItem("user_last_name", lastName);
                        // localStorage.setItem("user_email", email);
                        window.location.reload();
                    }

                }} severity={changesMade ? "success" : "info"} icon={changesMade ? <CheckCircleOutlineOutlined fontSize="inherit" /> : <InfoOutlined fontSize="inherit" />}>
                    {changesMade ? 'Profile updated successfully!' : 'No changes detected.'}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Account;
