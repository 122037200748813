import React from 'react';
import HistoryItemAccordion from '../HistoryItemAccordion';

const ConsultationsTab = ({ consultations }) => {
    return (
        <div>
            {consultations.map((consultation) => (
                <HistoryItemAccordion
                    key={consultation.id}
                    title={`Consultation on ${new Date(consultation.created_at).toLocaleDateString()}`}
                    details={consultation}
                />
            ))}
        </div>
    );
};

export default ConsultationsTab;
