import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, TextField, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import API from "../../../api/api";


async function createAppointment(reason, appointment_time, patient_id, user_id, institution_id, other_details, status) {
    let data = {
        "reason": reason,
        "patient": patient_id,
        "institution": institution_id,
        "staff": user_id,
        "appointment_time": appointment_time,
        "other_details": other_details,
        "status": status
    }

    await API.post("createappointment/", data)
        .then(resp => {
            console.log(resp.data)
            if (resp.status === 200) {
                alert("Appointment created successfully")
            }
        })
        .catch((e) => {
            console.log(e)
        })
}


function FollowUp({patient_fname, details, writeDetails, value, patient, institution, writeDate, reviewMode}) {


    return (
        <Accordion
            style={{
                borderRadius: 15,
                minWidth: 500
            }}
        >
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography>
                    Schedule follow-up review
                </Typography>
            </AccordionSummary>
            <hr/>
            <AccordionDetails>
                <Box>
                    <TextField
                        id={"details"}
                        label={"Other details"}
                        variant={"outlined"}
                        multiline={"true"}
                        style={{
                            minWidth: 470,
                            paddingBottom: 15
                        }}
                        value={details}
                        onChange={writeDetails}
                    />
                    <Typography variant={"h6"} marginBottom={1}>
                        Please select the Date and time to see {patient_fname}
                    </Typography>
                    <Stack spacing={3}>
                        <TextField
                            label="Next appointment"
                            type="datetime-local"
                            sx={{width: 250}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={value}
                            onChange={writeDate}
                        />
                    </Stack>

                    <Button
                        variant={"outlined"}
                        disabled={reviewMode}

                        onClick={() => {
                            createAppointment(
                                "test reason",
                                `${value}`,
                                `${patient}`,
                                `${localStorage.getItem('user_id')}`,
                                `${institution}`,
                                `${details}`,
                                "not_yet_seen"
                            ).then(r => {
                                // Flash a message
                                console.log(r)
                            })
                        }}>Create Appointment</Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default FollowUp;