import React, { useState } from "react";
import API from "../../../api/api";
import { Button, Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { NavLink } from "react-router-dom";
import InstitutionSelect from "./InstitutionSelect";
import Logout from "./CleanUpLogout";

export default function StaffLogin() {
    const [authenticated, setAuthenticated] = useState(
        localStorage.getItem("authenticated") || false
    );
    const [open, setOpen] = useState(false);
    const [institution, setInstitution] = useState([]);
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });

    // Handle form submission
    async function handleFormSubmit(data) {
        try {
            const resp_data = await API.post("login/user/", {
                email: data.email,
                password: data.password,
                user_type: "admin",
                institution: localStorage.getItem("institution_id"),
            });

            if (resp_data.status === 200) {
                setAuthenticated(true);
                localStorage.setItem("auth_token", resp_data.data["auth_token"]);
                localStorage.setItem("user_email", resp_data.data["user_email"]);
                localStorage.setItem("user_id", resp_data.data["id"]);
                localStorage.setItem("user_type", resp_data.data["user_type"]);
                localStorage.setItem("user_first_name", resp_data.data["user_first_name"]);
                localStorage.setItem("user_last_name", resp_data.data["user_last_name"]);
                localStorage.setItem("authenticated", true);

                if (resp_data.data.staff_type) {
                    localStorage.setItem("staff_type", resp_data.data.staff_type);
                } else if (resp_data.data.admin_type) {
                    localStorage.setItem("admin_type", resp_data.data.admin_type);
                }

                chooseHospital(resp_data.data["user_id"]);
            }
        } catch (e) {
            if (e.response?.status === 403) {
                alert(e.response.data["detail"]);
                Logout();
            } else {
                alert(e.response?.data?.["non_field_errors"]?.[0] || "An error occurred");
            }
        }
    }

    function chooseHospital(user_id) {
        API.get("accountaccess/", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
            params: {
                user_id: user_id,
            },
        })
            .then((resp_data) => {
                if (resp_data.data.results && resp_data.data.count > 0) {
                    setInstitution(resp_data.data.results);
                    setOpen(true);
                } else if (resp_data.data.results && resp_data.data.count === 0) {
                    alert("Your account has been suspended. Please contact your administrator.");
                    Logout();
                    localStorage.clear();
                }
            })
            .catch((e) => {
                console.log(e.response.data);
            });
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + "images/pages/signin-bg.jpg"})`,
                height: "100vh",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                padding: isMobile ? 10 : 50,
            }}
        >
            <InstitutionSelect open={open} setOpen={setOpen} institution={institution} />
            <Grid
                item
                xs={12}
                sm={8}
                md={4}
                style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: isMobile ? 20 : 40,
                }}
            >
                <Grid container direction="column" alignItems="center">
                    <img
                        src="/images/afyasasa-main-logo.png"
                        alt="AfyaSasa Logo"
                        width={isMobile ? "80%" : 236.05}
                        style={{ marginBottom: isMobile ? 20 : 40 }}
                    />
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: "Email is required",
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Please enter a valid email address",
                                },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    required
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={errors.email ? errors.email.message : null}
                                    error={!!errors.email}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: "Password is required",
                                minLength: {
                                    value: 6,
                                    message: "Password must be at least 6 characters long",
                                },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    required
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={errors.password ? errors.password.message : null}
                                    error={!!errors.password}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            startIcon={<CheckCircleIcon />}
                            style={{
                                borderRadius: 20,
                                backgroundColor: "#77c0a3",
                                color: "white",
                                marginTop: isMobile ? 15 : 30,
                            }}
                        >
                            Login
                        </Button>
                    </form>
                    <NavLink
                        to="/password_reset"
                        style={{
                            textDecoration: "none",
                            color: "#77c0a3",
                            marginTop: isMobile ? 15 : 30,
                        }}
                    >
                        <p>Forgot password?</p>
                    </NavLink>
                    <hr style={{ width: "100%", marginTop: isMobile ? 15 : 30 }} />
                    <NavLink
                        to="/new-org"
                        style={{ textDecoration: "none", color: "#77c0a3" }}
                    >
                        <p>New Organisation? Click here!</p>
                    </NavLink>
                </Grid>
            </Grid>
        </Grid>
    );
}
