import React, { useState } from 'react';
import {Button, TextField, Typography, Snackbar} from "@mui/material";

const TestResults = ({currentTest, testResults, setTestResults, setFile, file, updateTest}) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleUpdate = () => {
        updateTest(currentTest, testResults, file);
        setSnackbarOpen(true); // Show Snackbar
    };

    return (
        <>
            <Typography>
                Lab Technician Name: {localStorage.getItem("user_first_name")} <span>
            {localStorage.getItem("user_last_name")}</span>
            </Typography>
            <Typography>
                Test Name: {currentTest.test_name}
            </Typography>

            <br/>

            <TextField
                id={"image"}
                label={"Results"}
                variant={"outlined"}
                multiline
                style={{
                    minWidth: 400,
                    paddingBottom: 15
                }}
                value={testResults}
                onChange={e => setTestResults(e.target.value)}
                inputProps={{
                    style: {whiteSpace: "pre-wrap"}
                }}
            />
            <br/>
            <input
                type={"file"}
                accept={"application/pdf, image/*, text/csv, .csv, .doc, .docx, .xls, .xlsx"}
                onChange={(event) => setFile(event.target.files[0])}
            />
            <Button
                style={{
                    borderRadius: 10,
                    backgroundColor: "#da4167",
                    color: "white",
                    marginRight: 5,
                    marginTop: 10
                }}
                onClick={handleUpdate}
            >
                <Typography variant={"body1"}>Save</Typography>
            </Button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="Results updated successfully"
            />
        </>
    );
};

export default TestResults;
