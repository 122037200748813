import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Typography,
} from "@mui/material";
import InputField from "./components/InputField";
import API from "../../api/api";

export default function Triage({ patient, setConfirmation }) {
    const [systolic_bp, setSystolic] = useState("");
    const [diastolic_bp, setDiastolic] = useState("");
    const [respiratory_rate, setRespiratoryRate] = useState("");
    const [temperature, setTemperature] = useState(36);
    const [oxygen_saturation, setOxygenSaturation] = useState("");
    const [reason_for_visit, setReasonForVisit] = useState("");
    const [open, setOpen] = useState(false);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [errors, setErrors] = useState({}); // Validation errors

    useEffect(() => {
        API.get("staff/get_allowed_doctors/", {
            params: {
                institution_id: localStorage.getItem("institution_id"),
            },
        }).then((response) => {
            setAvailableDoctors(response.data);
        });
    }, []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    function triage(
        patient_id,
        systolic_bp,
        diastolic_bp,
        spo2,
        triage_id,
        doctor_id,
        reason_for_visit
    ) {
        let data = {
            institution_id: localStorage.getItem("institution_id"),
            patient_id: patient_id,
            systolic_bp: systolic_bp,
            diastolic_bp: diastolic_bp,
            resp_rate: respiratory_rate,
            spo2: spo2,
            seen: true,
            id: triage_id,
            reason: reason_for_visit,
            doctor_id: doctor_id || selectedDoctor?.id,
        };

        API.patch("triage/", data)
            .then((response) => {
                if (response.status === 201) {
                    alert("Triage successful");
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    function setDoctor(doctor_id: string) {
        const selected = availableDoctors.find((doc) => doc.id === doctor_id);
        if (selected) {
            setSelectedDoctor(selected); // Set the correct doctor object
        }
    }

    const handleBackClick = () => {
        setConfirmation(false);
    };

    const validateFields = () => {
        const newErrors = {};

        if (!systolic_bp) newErrors.systolic_bp = "Systolic BP is required.";
        if (!diastolic_bp) newErrors.diastolic_bp = "Diastolic BP is required.";
        if (!respiratory_rate) newErrors.respiratory_rate = "Respiratory rate is required.";
        if (!oxygen_saturation) newErrors.oxygen_saturation = "SPO2 is required.";
        if (!reason_for_visit) newErrors.reason_for_visit = "Reason for visit is required.";

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleSave = () => {
        if (validateFields()) {
            triage(
                `${patient.patient}`,
                `${systolic_bp}`,
                `${diastolic_bp}`,
                `${oxygen_saturation}`,
                `${patient.id}`,
                `${patient.staff || selectedDoctor?.id}`,
                `${reason_for_visit || patient.reason}`
            );
        }
    };

    return (
        <>
            <Button
                style={{
                    marginLeft: 15,
                }}
                variant={"contained"}
                onClick={handleBackClick}
            >
                Back
            </Button>
            <Grid
                container
                rowSpacing={2}
                justifyContent={"space-between"}
                style={{
                    backgroundColor: "white",
                    maxWidth: "96%",
                    padding: "10px",
                    marginLeft: "20px",
                    marginTop: "20px",
                }}
            >
                <Grid item>
                    <Typography variant={"h3"}>Triage Form</Typography>
                    <Typography variant={"h5"}>
                        Patient Name: {patient.patient_first_name} {patient.patient_last_name}
                    </Typography>
                    <Typography variant={"h5"}>
                        Doctor To see:{" "}
                        {patient.doctor_first_name || "Not assigned yet"} {patient.doctor_last_name}
                    </Typography>
                </Grid>

                <InputField
                    field_name={"Vitals Systolic BP"}
                    value={systolic_bp}
                    onChange={setSystolic}
                    required={true}
                    type={"number"}
                    field_label={"Systolic"}
                    error={!!errors.systolic_bp}
                    helperText={errors.systolic_bp}
                />

                <InputField
                    field_name={"Diastolic BP"}
                    value={diastolic_bp}
                    onChange={setDiastolic}
                    required={true}
                    type={"number"}
                    field_label={"Diastolic"}
                    error={!!errors.diastolic_bp}
                    helperText={errors.diastolic_bp}
                />

                <InputField
                    field_name={"Respiratory Rate"}
                    value={respiratory_rate}
                    onChange={setRespiratoryRate}
                    required={true}
                    field_label={"Resp Rate"}
                    error={!!errors.respiratory_rate}
                    helperText={errors.respiratory_rate}
                />
                <InputField
                    field_name={"Temperature"}
                    value={temperature}
                    onChange={setTemperature}
                    required={true}
                    type={"string"}
                    field_label={"Temperature"}
                />
                <InputField
                    field_name={"SPO2 Sat"}
                    value={oxygen_saturation}
                    required={true}
                    onChange={setOxygenSaturation}
                    field_label={"SPO2"}
                    error={!!errors.oxygen_saturation}
                    helperText={errors.oxygen_saturation}
                />
                <InputField
                    field_name={"Reason for visit"}
                    value={reason_for_visit || patient.reason}
                    onChange={setReasonForVisit}
                    field_label={"Reason"}
                    error={!!errors.reason_for_visit}
                    helperText={errors.reason_for_visit}
                />

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="doctor">
                        {selectedDoctor === null
                            ? `${patient.doctor_first_name || "Assign"} ${
                                patient.doctor_last_name || "Doctor"
                            }`
                            : "Assign Doctor"}
                    </InputLabel>
                    <Select
                        style={{ minWidth: 300 }}
                        labelId="doctor-label"
                        id="doctor"
                        value={selectedDoctor ? selectedDoctor.id : ""}
                        label="Assign Doctor"
                        disabled={!!patient.staff}
                    >
                        {availableDoctors.map(doctor => (
                            <MenuItem
                                key={doctor.id}
                                value={doctor.id}
                                onClick={() => {
                                    setDoctor(doctor.id); // Update selectedDoctor with the correct doctor id
                                }}
                            >
                                {doctor.first_name} {doctor.last_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Grid item>
                    <Button
                        variant={"contained"}
                        style={{
                            borderRadius: 10,
                            backgroundColor: "#77c0a3",
                            color: "white",
                            marginRight: 5,
                            marginTop: 10,
                        }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                            This is a success message!
                        </Alert>
                    </Snackbar>
                </Grid>
            </Grid>
        </>
    );
}
